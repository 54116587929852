import { Flex, Grid, Text, Image, Divider, Link } from '@chakra-ui/react'
import { NewsCard } from './NewsCard'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { newsCards } from '../../consts/news'

import image1 from '../../assets/news_image_1.png'
import redirect from '../../assets/redirect.svg'
import config from '../../config/configLoader'

function News() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const filteredNewsCards = newsCards[config.operator].filter(newsCard => newsCard.display !== 0)

  return (
    <Flex flex='1' direction='column'>
      <Image display={{ sm: 'flex', lg: 'none' }} w='100%' src={image1} />
      <Link
        className='chakra-custom-link'
        href={filteredNewsCards[0].url}
        target='_blank'
        rel='noopener noreferrer'
        _hover={{ textDecoration: 'none' }}
      >
        <Flex bg='white' flex='1'>
          <Flex
            direction='column'
            gap='16px'
            align='flex-start'
            justify='center'
            padding={{ sm: '32px 24px', md: '32px 24px', lg: '48px 48px', xl: '70px 130px' }}
            flex={{ lg: '6', xl: '4' }}
          >
            <Text
              fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }}
              fontWeight='700'
              color='#001D20'
              lineHeight={{ sm: '32px', lg: '49px' }}
            >
              {filteredNewsCards[0].title}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '18px', xl: '18px' }} color='#282828'>
              {filteredNewsCards[0].description}
            </Text>
            <Flex
              direction='column'
              gap='16px'
              align='flex-start'
              justify='end'
              width='100%'
              flexShrink='0'
              flexGrow='1'
            >
              <Divider border='1px solid #E6E6E6' />
              <Flex gap='16px' align='center' justify='space-between' width='100%'>
                <Text fontSize='14px' fontWeight='600' color='#475467'>
                  {t('home.x_min_read', { minutes: filteredNewsCards[0].duration })}
                </Text>
                <Flex gap='16px' align='center'>
                  <Text fontSize='13px' fontWeight='700' color='#475467'>
                    {filteredNewsCards[0].source}
                  </Text>
                  <Image src={redirect} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex display={{ sm: 'none', lg: 'flex' }} flex={{ sm: '10', lg: '6' }} w='100%' h='auto' overflow='hidden'>
            <Image src={image1} h='100%' w='100%' objectFit='cover' />
          </Flex>
        </Flex>
      </Link>

      <Flex
        bg='#F9F9FB'
        padding={{ sm: '32px 24px', md: '32px 24px', lg: '90px 130px', xl: '90px 130px' }}
        gap={{ sm: '32px', md: '32px', lg: '90px', xl: '90px' }}
        direction='column'
      >
        <Text
          textAlign='center'
          color='#001D20'
          fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }}
          fontWeight='700'
        >
          {t('news.all_news')}
        </Text>
        <Grid
          templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }}
          gap='16px'
        >
          {filteredNewsCards.map((newsCard, index) => {
            if (index > 0) {
              return (
                <NewsCard
                  image={newsCard.image}
                  url={newsCard.url}
                  title={newsCard.title}
                  description={newsCard.description}
                  source={newsCard.source}
                  duration={newsCard.duration}
                />
              )
            }
          })}
        </Grid>
      </Flex>
    </Flex>
  )
}

export default News
