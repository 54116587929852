import { Flex, Text, Image, Slide, Collapse } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import alertIcon from '../../../assets/alert_dark_yellow.svg'
import { useEffect, useState } from 'react'

export function TransactionErrorBanner({ errorID, isVisible }) {
  const { t } = useTranslation()

  return (
    <Collapse animateOpacity in={isVisible} width='100%'>
      <Flex
        display={isVisible ? 'flex' : 'none'}
        gap='16px'
        padding='16px'
        align='flex-start'
        bg='#FEECAA'
        boxShadow='0px 4px 10px 1px rgba(0, 0, 0, 0.1)'
        w='100%'
        zIndex='50'
      >
        <Image src={alertIcon} />
        <Flex direction='column' gap='8px' align='flex-start' justify='center'>
          {(errorID === 6000 || errorID === 10012 || errorID === 10014 || errorID === 10210) && (
            <>
              <Text fontSize='14px' fontWeight='700' color='#91600E'>
                {t('ticket_fares.invalid_card_number_title')}
              </Text>
              <Text fontSize='13px' color='#1D2939'>
                {t('ticket_fares.invalid_card_number_subtitle')}
              </Text>
            </>
          )}
          {(errorID === 2061 || errorID === 2062) && (
            <>
              <Text fontSize='14px' fontWeight='700' color='#91600E'>
                {t('ticket_fares.card_authentication_failed_title')}
              </Text>
              <Text fontSize='13px' color='#1D2939'>
                {t('ticket_fares.card_authentication_failed_subtitle')}
              </Text>
            </>
          )}
          {errorID === 10054 && (
            <>
              <Text fontSize='14px' fontWeight='700' color='#91600E'>
                {t('ticket_fares.expired_bank_card_title')}
              </Text>
              <Text fontSize='13px' color='#1D2939'>
                {t('ticket_fares.expired_bank_card_subtitle')}
              </Text>
            </>
          )}
          {(errorID === 10001 ||
            errorID === 10003 ||
            errorID === 10005 ||
            errorID === 10006 ||
            errorID === 10039 ||
            errorID === 10046 ||
            errorID === 100052 ||
            errorID === 10053 ||
            errorID === 10057 ||
            errorID === 10058 ||
            errorID === 10059 ||
            errorID === 10062 ||
            errorID === 10063 ||
            errorID === 10070 ||
            errorID === 100077 ||
            errorID === 10079 ||
            errorID === 10083 ||
            errorID === 10063 ||
            errorID === 10211 ||
            errorID === 100213 ||
            errorID === 10214 ||
            errorID === 10216) && (
            <>
              <Text fontSize='14px' fontWeight='700' color='#91600E'>
                {t('ticket_fares.failed_transaction_title')}
              </Text>
              <Text fontSize='13px' color='#1D2939'>
                {t('ticket_fares.failed_transaction_subtitle')}
              </Text>
            </>
          )}
          {errorID === 10051 && (
            <>
              <Text fontSize='14px' fontWeight='700' color='#91600E'>
                {t('ticket_fares.failed_transaction_title')}
              </Text>
              <Text fontSize='13px' color='#1D2939'>
                {t('ticket_fares.failed_transaction_insufficient_funds_subtitle')}
              </Text>
            </>
          )}
          {(errorID === 10004 ||
            errorID === 10007 ||
            errorID === 10041 ||
            errorID === 10043 ||
            errorID === 10093 ||
            errorID === 10212) && (
            <>
              <Text fontSize='14px' fontWeight='700' color='#91600E'>
                {t('ticket_fares.blocked_bank_card_title')}
              </Text>
              <Text fontSize='13px' color='#1D2939'>
                {t('ticket_fares.blocked_bank_card_subtitle')}
              </Text>
            </>
          )}
          {errorID === 10061 && (
            <>
              <Text fontSize='14px' fontWeight='700' color='#91600E'>
                {t('ticket_fares.failed_transaction_title')}
              </Text>
              <Text fontSize='13px' color='#1D2939'>
                {t('ticket_fares.failed_transaction_exceeds_limit_subtitle')}
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </Collapse>
  )
}
