import { Flex, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import React from 'react'
import { useTranslation } from 'react-i18next'
import alert from '../../assets/alert_dark_yellow.svg'
import { IconWithinCircle } from '../../pages/Common/IconWithinCircle'

export const ErrorRequestModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ sm: 'xs', md: 'xs', lg: '', xl: '' }}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        borderRadius='8px'
        maxW='600px'
        padding={{ sm: '48px 24px', md: '48px 24px', lg: '64px 56px', xl: '64px 56px' }}
      >
          <Flex direction='column' gap='32px' align='center' justify='center' textAlign='center'>
              <IconWithinCircle color='warning.400' icon={alert} width='120px' />
              <Text fontSize='24px' fontWeight='700' color='#1D2939'>
                {t('ticket_fares.request_failed')}
              </Text>
              <Text fontSize='16px' fontWeight='600' color='1D2939'>
                {t('ticket_fares.request_failed_subtitle')}
              </Text>
              <Flex gap='16px' display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}>
                <UbiButton
                  onClick={onClose}
                  style='solid'
                  type='warning'
                  label={t('common.ok')}
                  css={{ width: '152px' }}
                />
              </Flex>
              <Flex
                gap='16px'
                alignSelf='stretch'
                direction='column'
                display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}
              >
                <UbiButton
                  onClick={onClose}
                  style='solid'
                  type='warning'
                  label={t('common.ok')}
                  css={{ width: '100%' }}
                />
              </Flex>
            </Flex>
      </ModalContent>
    </Modal>
  )
}
