import { Flex, Modal, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { agencyID, getNetworkInfo } from '../../api/requests'
import config from '../../config/configLoader'
import { ScheduleHeader } from './components/ScheduleHeader'
import { CalendarInfo } from './components/CalendarInfo'
import { RouteScheduleTable } from './components/RouteScheduleTable'
import { StopScheduleTable } from './components/StopScheduleTable'
import { DateTime } from 'luxon'
import { ScheduleTableHeader } from './components/ScheduleTableHeader'
import { customLog } from '../../helpers/log'

export const ScheduleModal = props => {
  const methods = useForm({
    defaultValues: {
      pattern: props.selectedPattern || 0,
      date: DateTime.now(),
    },
  })

  const routePattern = methods.watch('pattern')
  const currentDay = methods.watch('date')

  useEffect(() => {
    if (props.isOpen) {
      methods.reset({
        pattern: props.selectedPattern,
        date: DateTime.now(),
      })
    }
  }, [props.isOpen])

  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
  const currentWeekday = currentDay?.toFormat('cccc').toLowerCase()
  const filteredResults = []

  const [schedulesRoute, setSchedulesRoute] = useState([])
  const [calendars, setCalendars] = useState([])
  const [schedulesStop, setSchedulesStop] = useState([])
  const [exceptionDates, setExceptionDates] = useState([])
  const [isException, toggleException] = useState(false)
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [dayNumber, setDayNumber] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getRouteSchedules = async () => {
      setLoading(true)
      const pattern = Object.values(props?.network.route.patterns?.entities || {})[routePattern]

      if (pattern !== undefined) {
        const routeSchedule = await getNetworkInfo('network/getRouteSchedules', {
          agencyID: agencyID,
          routeID: props.selectedRoute,
          patternID: pattern.patternID,
          date: currentDay.toFormat('yyyy-MM-dd'),
        })

        customLog(routeSchedule)

        if (!minDate && !maxDate) {
          setMinDate(
            routeSchedule.calendars !== undefined
              ? Object.values(routeSchedule.calendars)
                  .map(calendar => DateTime.fromISO(calendar.startDate))
                  .reduce((min, date) => (date < min ? date : min))
              : null
          )
          setMaxDate(
            routeSchedule.calendars !== undefined
              ? Object.values(routeSchedule.calendars)
                  .map(calendar => DateTime.fromISO(calendar.endDate))
                  .reduce((max, date) => (date > max ? date : max))
              : null
          )
        }

        const dayN = getCalendarID(routeSchedule.calendars, routeSchedule.isExceptionDate)
        const stopValues = pattern.stopSequence
        const filteredStops = props.network?.stops.filter(item => stopValues.includes(item.id))
        const sortedArray = filteredStops.sort((a, b) => {
          const stopIdA = a.id
          const stopIdB = b.id
          return stopValues.indexOf(stopIdA) - stopValues.indexOf(stopIdB)
        })

        dayN.forEach(day => {
          if (day !== undefined && routeSchedule.calendars[day].isActive) {
            for (const [index, stop] of stopValues.entries()) {
              const schedule = {
                stop: sortedArray.find(obj => obj.id === stop),
                schedule: routeSchedule.schedules[day]?.map(subArray => subArray[index]),
              }
              setSchedulesRoute(prevArray => [...prevArray, schedule])
            }
          }
        })

        toggleException(routeSchedule.isExceptionDate)
        setCalendars(routeSchedule.calendars)
        setExceptionDates(routeSchedule.exceptions)
        setDayNumber(dayN)
      }
      setLoading(false)
    }

    const getStopSchedules = async () => {
      setLoading(true)

      const stopSchedule = await getNetworkInfo('network/getStopSchedules', {
        agencyID: agencyID,
        stopID: props.selectedStop,
        date: currentDay.toFormat('yyyy-MM-dd'),
      })

      customLog(stopSchedule)

      if (!minDate && !maxDate) {
        setMinDate(
          stopSchedule.calendars !== undefined
            ? Object.values(stopSchedule.calendars)
                .map(calendar => DateTime.fromISO(calendar.startDate))
                .reduce((min, date) => (date < min ? date : min))
            : null
        )
        setMaxDate(
          stopSchedule.calendars !== undefined
            ? Object.values(stopSchedule.calendars)
                .map(calendar => DateTime.fromISO(calendar.endDate))
                .reduce((max, date) => (date > max ? date : max))
            : null
        )
      }

      const dayN = getCalendarID(stopSchedule.calendars, stopSchedule.isExceptionDate)
      const routeIDs = {}

      Object.keys(stopSchedule.hours).forEach(calendar => {
        const data = stopSchedule.hours[calendar]
        const uniqueRouteIDs = [...new Set(data.map(item => item.routeID))]
        routeIDs[calendar] = uniqueRouteIDs.map(Number)
      })

      dayN.forEach(day => {
        if (day !== undefined && stopSchedule.calendars[day].isActive) {
          routeIDs[day.toString()]?.map(routeID => {
            const schedule = stopSchedule.hours[day]
            if (schedule !== undefined) {
              const filteredData = schedule.filter(item => item.routeID === routeID)
              filteredData.forEach(item => {
                const { direction, time } = item
                filteredResults.push({ routeID, direction, time })
              })
            }
          })
        }
      })

      const mergedResults = filteredResults.reduce((result, current) => {
        const existingItem = result.find(
          item => item.routeID === current.routeID && item.direction === current.direction
        )
        if (existingItem) {
          existingItem.time.push(current.time)
        } else {
          result.push({
            routeID: current.routeID,
            direction: current.direction,
            time: [current.time],
          })
        }
        return result
      }, [])

      toggleException(stopSchedule.isExceptionDate)
      setCalendars(stopSchedule.calendars)
      setExceptionDates(stopSchedule.exceptions)
      setDayNumber(dayN)
      setSchedulesStop(mergedResults || null)
      setLoading(false)
    }

    const today = DateTime.now().startOf('day')
    const isSameDay =
      currentDay.hasSame(today, 'day') && currentDay.hasSame(today, 'month') && currentDay.hasSame(today, 'year')

    if (props.isOpen) {
      setSchedulesRoute([])
      setSchedulesStop([])

      if (props.selectedStop) {
        getStopSchedules()
      } else if (!props.selectedStop) {
        getRouteSchedules()
      }
    } else if (!isSameDay && !props.isOpen) {
      setSchedulesRoute([])
      setSchedulesStop([])
      setMinDate(null)
      setMaxDate(null)
      methods.reset({
        date: DateTime.now(),
        pattern: props.selectedPattern || 0,
      })
    }
  }, [routePattern, props.isOpen, currentDay])

  const getCalendarID = (calendars, exception) => {
    const weekdayCalendarID = Object.values(calendars).find(
      calendar =>
        calendar.monday &&
        calendar.tuesday &&
        calendar.wednesday &&
        calendar.thursday &&
        calendar.friday &&
        calendar.isActive
    )?.id
    const saturdayCalendarID = Object.values(calendars).find(
      calendar => calendar.saturday && calendar.isActive
    )?.id
    const sundayCalendarID = Object.values(calendars).find(calendar => calendar.sunday && calendar.isActive)?.id
    const allIDs = Object.values(calendars).map(calendar => calendar.id)
    customLog(calendars, allIDs, weekdayCalendarID, saturdayCalendarID, sundayCalendarID, exception)
    return exception
      ? allIDs
      : weekdays.includes(currentWeekday)
        ? [weekdayCalendarID]
        : currentWeekday === 'saturday'
          ? [saturdayCalendarID]
          : [sundayCalendarID]
  }

  const windowSize = useRef([window.innerWidth, window.innerHeight])

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size={{ sm: 'full', lg: '' }}
      trapFocus={false}
      autoFocus={false}
      isCentered={windowSize.current[0] >= 960}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={{ lg: '8px' }}
        border={{ lg: '1px solid ' + config.mainColor }}
        w={{ sm: '100%', lg: '880px', xl: '1100px' }}
        mt={{ lg: '32px' }}
        mb={{ lg: '32px' }}
      >
        <FormProvider {...methods}>
          <Flex
            direction='column'
            w='100%'
            h='100%'
            borderRadius='8px'
            mt={{ sm: '70px', md: '70px', lg: '0px', xl: '0px' }}
          >
            <ScheduleHeader
              network={props.network}
              selectedRoute={props.selectedRoute}
              selectedStop={props.selectedStop}
              selectedPattern={routePattern}
              isException={isException}
              loading={loading}
              closeSchedules={props.onClose}
            />

            <Flex direction='column' gap='16px' align='flex-start' w='100%' h='100%'>
              {currentWeekday && calendars && Object.values(calendars).length > 0 && (
                <CalendarInfo
                  selectedStop={props.selectedStop}
                  hasSchedules={props.selectedStop ? schedulesStop.length > 0 : schedulesRoute.length > 0}
                  calendars={calendars}
                  currentDay={currentDay}
                  currentDate={dayNumber}
                  currentWeekday={currentWeekday}
                  exceptionDates={exceptionDates}
                  routePattern={routePattern}
                  loading={loading}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              )}

              {loading && (
                <Flex align='center' justify='center' h='390px' w='100%'>
                  <Spinner thickness='10px' color={config.mainColor} width='100px' height='100px' />
                </Flex>
              )}

              {!loading && (
                <ScheduleTableHeader
                  selectedStop={props.selectedStop}
                  schedulesRoute={schedulesRoute}
                  schedulesStop={schedulesStop}
                />
              )}

              <Flex w='100%' mt='-16px' direction='column' maxH={{ lg: '344px' }} overflow='scroll'>
                {!props.selectedStop && !loading && (
                  <RouteScheduleTable
                    schedules={schedulesRoute}
                    allNetwork={props.allNetwork}
                    currentWeekday={currentWeekday}
                    selectedRoute={props.selectedRoute}
                    isException={isException}
                  />
                )}

                {props.selectedStop && !loading && (
                  <StopScheduleTable
                    schedules={schedulesStop}
                    network={props.network}
                    allNetwork={props.allNetwork}
                    currentWeekday={currentWeekday}
                    isException={isException}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        </FormProvider>
      </ModalContent>
    </Modal>
  )
}
