import { Flex, Text, Divider } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { Button, RouteLabel, TransportIcon } from '@ubirider/pick-component-library'
import { useNavigate } from 'react-router-dom'

function TripCard() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Flex
      direction='column'
      gap='24px'
      padding='24px'
      border='1px solid #CACFD8'
      bg='white'
      box-shadow='0px 2px 20px 1px rgba(0, 0, 0, 0.1)'
      borderRadius='8px'
      w='100%'
    >
      <Flex justify='space-between' align='center' gap='48px' w='100%'>
        <Flex align='flex-start' gap='16px'>
          <Flex direction='column'>
            <Text fontSize='18px' fontWeight='700' color='#1D2939'>
              timeStart
            </Text>
            <Text fontSize='14px' fontWeight='600' color='#667085'>
              stopStart
            </Text>
          </Flex>
          <Text fontSize='20px' fontWeight='700' color='#1D2939'>
            —
          </Text>
          <Flex direction='column'>
            <Text fontSize='18px' fontWeight='700' color='#1D2939'>
              timeEnd
            </Text>
            <Text fontSize='14px' fontWeight='600' color='#667085'>
              stopEnd
            </Text>
          </Flex>
        </Flex>
        <Flex align='center' justify='flex-end' gap='16px'>
          <Text fontSize='14px' fontWeight='600' color='#1D2939'>
            from
          </Text>
          <Text fontSize='30px' fontWeight='700' color='#1D2939'>
            price
          </Text>
        </Flex>
      </Flex>
      <Flex justify='space-between' align='center' gap='48px' w='100%'>
        <Flex gap='48px' align='flex-start'>
          <Flex gap='8px'>
            <TransportIcon type={3} />
            <RouteLabel shortName={'45'} color={'red'} textColor={'white'} />
          </Flex>
          <Flex gap='8px' align='center'>
            <Text fontSize='20px' fontWeight='700' color='#1D2939'>
              duration
            </Text>
            <Text fontSize='14px' fontWeight='600' color='#667085'>
              duration
            </Text>
          </Flex>
          <Flex gap='8px' align='center'>
            <Text fontSize='20px' fontWeight='700' color='#1D2939'>
              connection
            </Text>
            <Text fontSize='14px' fontWeight='600' color='#667085'>
              connection
            </Text>
          </Flex>
          <Flex gap='8px' align='center'>
            <Text fontSize='20px' fontWeight='700' color='#1D2939'>
              passengers
            </Text>
            <Text fontSize='14px' fontWeight='600' color='#667085'>
              passengers
            </Text>
          </Flex>
        </Flex>
        <Button
          onClick={() => navigate('/payment')}
          type='primary'
          style='solid'
          label={'Select'}
          css={{ width: '160px' }}
        />
      </Flex>
      <Divider border='1px solid #CACFD8' />
      <Flex>
        <Text fontSize='16px' fontWeight='600' color='#98A2B3'>
          trip details
        </Text>
      </Flex>
    </Flex>
  )
}

export default TripCard
