import { Flex, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import React from 'react'

function BrowserTable() {
  const { t } = useTranslation()

  const language = localStorage.getItem('Language')

  return (
    <Flex direction='column' padding='8px' justify='center' bg='neutral.25' border='1px solid #EAECF0'>
      <Flex w='100%' borderBottom='1px solid #EAECF0'>
        <Flex w='205px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' fontWeight='700'>
            {t('cookie_policy.cookie_policy_browser_table_1')}
          </Text>
        </Flex>
        <Flex w='calc(100% - 205px)' direction='column' padding='14px 16px' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' color='#475467'>
            {t('cookie_policy.cookie_policy_browser_table_2')}
          </Text>
          <UnorderedList>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_4')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_5')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_6')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_7')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_8')}
              </Text>
            </ListItem>
            <ListItem ml='24px'>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_9')}
              </Text>
            </ListItem>
            <ListItem ml='24px'>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_10')}
              </Text>
            </ListItem>
            <ListItem ml='24px'>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_11')}
              </Text>
            </ListItem>
            <ListItem ml='24px'>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_12')}
              </Text>
            </ListItem>
            <ListItem ml='24px'>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_13')}
              </Text>
            </ListItem>
          </UnorderedList>
          <Text fontSize='14px' color='#475467' mt='24px'>
            <Trans i18nKey='cookie_policy.cookie_policy_browser_table_14'>
              For more information on cookie settings in Google, see the{' '}
              <Link
                className='chakra-custom-link'
                sx={{ textDecoration: 'underline', fontWeight: '600' }}
                href={`https://support.google.com/chrome/answer/95647?hl=${language === 'PT' ? 'pt' : 'en'}&co=GENIE.Platform%3DDesktop&sjid=142129615948642115-EU`}
                target='_blank'
              >
                Chrome Help
              </Link>{' '}
              page.
            </Trans>
          </Text>
        </Flex>
      </Flex>
      <Flex w='100%' borderBottom='1px solid #EAECF0'>
        <Flex w='205px' padding='14px 16px' align='center' alignSelf='stretch' borderBottom='1px solid #EAECF0'>
          <Text fontSize='14px' fontWeight='700'>
            {t('cookie_policy.cookie_policy_browser_table_15')}
          </Text>
        </Flex>
        <Flex w='calc(100% - 205px)' direction='column' padding='14px 16px' borderBottom='1px solid #EAECF0'>
          <UnorderedList>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_16')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_17')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_18')}
              </Text>
            </ListItem>
          </UnorderedList>
          <Text fontSize='14px' color='#475467' mt='24px'>
            <Trans i18nKey='cookie_policy.cookie_policy_browser_table_19'>
              For more information on cookie settings in Microsoft, see{' '}
              <Link
                className='chakra-custom-link'
                sx={{ textDecoration: 'underline', fontWeight: '600' }}
                href={`https://support.microsoft.com/${language === 'PT' ? 'pt-pt' : 'en-us'}/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d`}
                target='_blank'
              >
                Microsoft's
              </Link>{' '}
              page.
            </Trans>
          </Text>
        </Flex>
      </Flex>
      <Flex w='100%' borderBottom='1px solid #EAECF0'>
        <Flex w='205px' padding='14px 16px' align='center' alignSelf='stretch'>
          <Text fontSize='14px' fontWeight='700'>
            {t('cookie_policy.cookie_policy_browser_table_20')}
          </Text>
        </Flex>
        <Flex w='calc(100% - 205px)' direction='column' padding='14px 16px' borderBottom='1px solid #EAECF0'>
          <UnorderedList>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_21')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_22')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_23')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_24')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_25')}
              </Text>
            </ListItem>
          </UnorderedList>
          <Text fontSize='14px' color='#475467' mt='24px'>
            <Trans i18nKey='cookie_policy.cookie_policy_browser_table_26'>
              For more information on cookie settings in Firefox, see{' '}
              <Link
                className='chakra-custom-link'
                sx={{ textDecoration: 'underline', fontWeight: '600' }}
                href={`https://support.mozilla.org/${language === 'PT' ? 'pt-PT/kb/bloquear-os-sites-para-nao-guardarem-cookies-dados-site-firefox' : 'en-US/kb/block-websites-storing-cookies-site-data-firefox'}`}
                target='_blank'
              >
                Mozilla Support
              </Link>{' '}
              page.
            </Trans>
          </Text>
        </Flex>
      </Flex>
      <Flex>
        <Flex w='205px' padding='14px 16px' align='center' alignSelf='stretch'>
          <Text fontSize='14px' fontWeight='700'>
            {t('cookie_policy.cookie_policy_browser_table_27')}
          </Text>
        </Flex>
        <Flex w='calc(100% - 205px)' direction='column' padding='14px 16px'>
          <UnorderedList>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_28')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_29')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_30')}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize='14px' color='#475467'>
                {t('cookie_policy.cookie_policy_browser_table_31')}
              </Text>
            </ListItem>
          </UnorderedList>
          <Text fontSize='14px' color='#475467' mt='24px'>
            <Trans i18nKey='cookie_policy.cookie_policy_browser_table_32'>
              For more information on cookie settings in Safari, see{' '}
              <Link
                className='chakra-custom-link'
                sx={{ textDecoration: 'underline', fontWeight: '600' }}
                href={`https://support.apple.com/${language === 'PT' ? 'pt-pt' : 'en-us'}/HT201265`}
                target='_blank'
              >
                Apple's
              </Link>{' '}
              page.
            </Trans>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default BrowserTable
