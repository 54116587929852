import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

function TermsConditions() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex direction='column' flex='1' bg='#F9F9FB'>
      <Flex
        direction='column'
        gap={{ sm: '48px', md: '48px', lg: '80px', xl: '80px' }}
        padding={{ sm: '32px 24px', md: '32px 24px', lg: '100px 130px', xl: '100px 130px' }}
        width='100%'
      >
        <Text
          fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }}
          fontWeight='700'
          color='#001D20'
          align='center'
          justify='center'
        >
          {t('terms_conditions.terms_conditions_title')}
        </Text>

        <Flex
          w='100%'
          direction='column'
          gap='16px'
          padding={{ sm: '20px', md: '20px', lg: '40px', xl: '40px' }}
          align='flex-start'
          justify='center'
          bg='white'
          border='1px solid #D3D3D3'
          borderRadius='8px'
          flexWrap='wrap'
        >
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
            {t('terms_conditions.terms_conditions_description_1')}
          </Text>
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
            {t('terms_conditions.terms_conditions_description_2')}
          </Text>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_3')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_4')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_5')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_6')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_7')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_8')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_9')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_10')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_11')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_12')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_13')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_14')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_15')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_16')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_17')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_18')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_19')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_20')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_21')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_22')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_23')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_24')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_25')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_26')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_27')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_28')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_29')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_30')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_31')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_32')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_33')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_34')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_35')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_36')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_37')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_38')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_39')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_40')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_41')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_42')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }} fontWeight='700' color='#282828'>
              {t('terms_conditions.terms_conditions_description_43')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828'>
              {t('terms_conditions.terms_conditions_description_44')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default TermsConditions
