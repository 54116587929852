import { Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FormItem, RouteLabel, Select } from '@ubirider/pick-component-library'
import closeButton from '../../../../assets/cross.svg'
import stopSign from '../../../../assets/stop_sign.svg'
import { ScheduleNumbers } from '../../../../consts/schedules'
import config from '../../../../config/configLoader'
import downloadIcon from '../../../../assets/download_white.svg'
import { Controller, useFormContext } from 'react-hook-form'
import arrowBack from '../../../../assets/arrow_left_simple.svg'
import { DateTime } from 'luxon'

export function ScheduleHeader({
  network,
  selectedRoute,
  selectedStop,
  selectedPattern,
  isException,
  loading,
  closeSchedules,
}) {
  const { t } = useTranslation()

  const { control, reset } = useFormContext()

  const routePatterns = Object.values(network?.route.patterns?.entities || {}).map(entity => entity?.name)

  const patternOptions =
    routePatterns && routePatterns.length > 0
      ? routePatterns?.map((value, index) => ({
          label: value,
          value: index,
        }))
      : null

  return (
    <Flex gap={{ sm: '16px', lg: '24px' }} direction='column' boxShadow={{ lg: '0px 2px 20px 1px rgba(0, 0, 0, 0.1)' }}>
      <Flex
        gap={{ sm: '16px', lg: '8px' }}
        direction='column'
        padding={{ sm: '16px 16px 0 16px', lg: '16px 24px' }}
        align={{ sm: 'flex-start', lg: 'center' }}
        w='100%'
      >
        {isException && (
          <>
            {!selectedStop && (
              <Flex
                direction='column'
                gap={{ sm: '16px', lg: '8px' }}
                align={{ sm: 'flex-start', lg: 'center' }}
                w='100%'
              >
                <Flex justify='space-between' align='center' w='100%'>
                  <Button
                    onClick={() => reset({ date: DateTime.now(), pattern: selectedPattern })}
                    type={'neutralLight'}
                    style={'ghost'}
                    size={'small'}
                    label={t('common.back')}
                    leftIconProps={{ src: arrowBack }}
                    css={{ justifyContent: 'flex-start' }}
                  />
                  <Flex as='button' align='flex-start' onClick={closeSchedules}>
                    <Image src={closeButton} />
                  </Flex>
                </Flex>
                <Flex gap='24px' align='center' justify='space-between' w='100%'>
                  <Flex gap='24px' align='center' justify='flex-start'>
                    <RouteLabel
                      shortName={network?.route.shortName}
                      color={network?.route.color}
                      textColor={network?.route.textColor}
                    />
                    <Text fontSize={{ sm: '18px', lg: '24px' }} fontWeight='700' color='#282828'>
                      {network?.route.name.replace(
                        /([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g,
                        match => match.charAt(0) + match.slice(1).toLowerCase()
                      )}
                    </Text>
                  </Flex>
                  {config.sections.schedules.download && (
                    <Flex display={{ sm: 'none', lg: 'flex' }}>
                      <a
                        href={ScheduleNumbers[config.operator][selectedRoute]?.link}
                        download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
                        target='_blank'
                        display={{ sm: 'none', lg: 'flex' }}
                        rel='noreferrer'
                      >
                        <Button
                          disabled={loading || ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                          style='solid'
                          size='small'
                          type='neutralDark'
                          label={t('common.download')}
                          rightIconProps={{ src: downloadIcon }}
                        />
                      </a>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            )}
            {selectedStop && (
              <Flex
                direction='column'
                gap={{ sm: '16px', lg: '8px' }}
                align={{ sm: 'flex-start', lg: 'center' }}
                w='100%'
              >
                <Flex justify='space-between' align='center' w='100%'>
                  <Button
                    onClick={() => reset({ date: DateTime.now() })}
                    type={'neutralLight'}
                    style={'ghost'}
                    size={'small'}
                    label={t('common.back')}
                    leftIconProps={{ src: arrowBack }}
                    css={{ justifyContent: 'flex-start' }}
                  />
                  <Flex as='button' align='flex-start' onClick={closeSchedules}>
                    <Image src={closeButton} />
                  </Flex>
                </Flex>
                <Flex align='center' justify='space-between' w='100%'>
                  <Flex gap={{ sm: '8px', lg: '24px' }} align='center'>
                    <Image src={stopSign} />
                    <Text fontSize={{ sm: '18px', lg: '24px' }} fontWeight='700' color='#282828'>
                      {network?.stops?.find(item => item.id === selectedStop)?.name}
                    </Text>
                  </Flex>
                  <Flex display={{ sm: 'none', lg: 'flex' }}>
                    <a
                      href={ScheduleNumbers[config.operator][selectedRoute]?.link}
                      download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
                      target='_blank'
                      display={{ sm: 'none', lg: 'flex' }}
                      rel='noreferrer'
                    >
                      <Button
                        disabled={loading || ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                        style='solid'
                        size='small'
                        type='neutralDark'
                        label={t('common.download')}
                        rightIconProps={{ src: downloadIcon }}
                      />
                    </a>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </>
        )}
        {!isException && (
          <>
            {!selectedStop && (
              <Flex justify='space-between' align='center' w='100%'>
                <Flex gap={{ sm: '8px', lg: '24px' }} maxW='90%' align='center' justify='flex-start'>
                  <RouteLabel
                    shortName={network?.route.shortName}
                    color={network?.route.color}
                    textColor={network?.route.textColor}
                  />
                  <Text fontSize={{ sm: '18px', lg: '24px' }} fontWeight='700' color='#282828'>
                    {network?.route.name.replace(
                      /([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g,
                      match => match.charAt(0) + match.slice(1).toLowerCase()
                    )}
                  </Text>
                </Flex>
                <Flex gap='24px' align='center' justify='flex-end'>
                  {config.sections.schedules.download && (
                    <Flex gap='24px' align='center' justify='flex-end' display={{ sm: 'none', lg: 'flex' }}>
                      <a
                        href={ScheduleNumbers[config.operator][selectedRoute]?.link}
                        download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Button
                          disabled={loading || ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                          style='solid'
                          size='small'
                          type='neutralDark'
                          label={t('common.download')}
                          rightIconProps={{ src: downloadIcon }}
                        />
                      </a>
                    </Flex>
                  )}
                  <Flex as='button' onClick={closeSchedules}>
                    <Image src={closeButton} />
                  </Flex>
                </Flex>
              </Flex>
            )}
            {selectedStop && (
              <Flex align='center' justify='space-between' w='100%'>
                <Flex gap={{ sm: '8px', lg: '24px' }} maxW='90%' align='center'>
                  <Image src={stopSign} />
                  <Text fontSize={{ sm: '18px', lg: '24px' }} fontWeight='700' color='#282828'>
                    {network?.stops?.find(item => item.id === selectedStop)?.name}
                  </Text>
                </Flex>
                <Flex as='button' onClick={closeSchedules}>
                  <Image src={closeButton} />
                </Flex>
              </Flex>
            )}
          </>
        )}

        <Flex ml={{ lg: '132px' }} direction='column' gap='8px' align='flex-start' w='100%'>
          {!selectedStop && (
            <Flex gap='8px' minW={{ sm: '100%', lg: '440px' }} align='center'>
              <Text display={{ sm: 'none', lg: 'flex' }} fontSize='16px' fontWeight='700' color='#475467'>
                {t('network_schedule.direction')}
              </Text>
              <Controller
                name='pattern'
                control={control}
                render={({ field }) => (
                  <FormItem label={''}>
                    <Select
                      background={'white'}
                      options={patternOptions}
                      {...field}
                      css={{ textAlign: 'left', border: 'none !important' }}
                    />
                  </FormItem>
                )}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
