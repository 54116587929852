import { Flex, Image } from '@chakra-ui/react'
import React from 'react'

import routeChangeDesktop from '../../../../../../assets/route_change_trevo_desktop.png'
import routeChangeMobile from '../../../../../../assets/route_change_trevo_mobile.png'

export function TrevoRouteChange() {
  return (
    <Flex h={{ sm: '716px' }} w='100%' align='center' justify='center' position='relative' overflow='hidden'>
        <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} w={'100%'} justify="center" align="center">
          <Image src={routeChangeDesktop} objectPosition={'left center!important'} w={'100%'} />
        </Flex>

        <Flex display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }} w={'100%'} justify="center" align="center">
          <Image src={routeChangeMobile} maxH={'716px'} objectFit={'fill !important'} objectPosition={'center!important'} />
        </Flex>
    </Flex>
  )
}
