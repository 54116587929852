export const decodePolylinePath = paths => {
  if (!paths) return null
  if (typeof paths === 'string') {
    return window.google.maps.geometry.encoding.decodePath(paths)
  } else if (paths.length === 1) {
    const pathArray = []
    const polyline = paths.toString()
    pathArray.push(window.google.maps.geometry.encoding.decodePath(polyline))
    return pathArray
  } else {
    const pathArray = []
    paths.forEach(polyline => {
      pathArray.push(window.google.maps.geometry.encoding.decodePath(polyline))
    })
    return pathArray
  }
}

export const getBoundsFromMultiPolylinePath = polylinePath => {
  const bounds = new window.google.maps.LatLngBounds()

  polylinePath.forEach(poly => {
    poly.forEach(path => {
      path.forEach(point => {
        bounds.extend(point)
      })
    })
  })

  return bounds
}

export const getBoundsFromPolylinePath = polylinePath => {
  const bounds = new window.google.maps.LatLngBounds()

  console.log(polylinePath)

  polylinePath.forEach(path => {
    path?.forEach(point => {
      bounds.extend(point)
    })
  })

  return bounds
}
