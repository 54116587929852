import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { getTextColor } from '../../../../helpers/color'

export function AboutMainSection() {
  const { t } = useTranslation()
  return (
    <Flex
      direction='column'
      gap='48px'
      padding={{ sm: '48px 24px', md: '48px 24px', lg: '0 130px', xl: '0 130px' }}
      align={{ sm: 'center', md: 'center', lg: 'flex-start', xl: 'flex-start' }}
      justify='center'
      width='100%'
    >
      <Flex
        direction='column'
        gap={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }}
        align={{ sm: 'center', md: 'center', lg: 'flex-start', xl: 'flex-start' }}
      >
        <Text
          textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}
          fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }}
          fontWeight='700'
          color={getTextColor()}
        >
          {t('about.about_operator')}
        </Text>
        <Flex direction='column' align='flex-start'>
          <Text fontSize='14px' color={getTextColor()}>
            {t('about.about_description_1')}
          </Text>
          <Text fontSize='14px' color={getTextColor()}>
            {t('about.about_description_2')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
