import { Flex, Skeleton, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateInput, FormItem, Label } from '@ubirider/pick-component-library'
import { Controller, useFormContext } from 'react-hook-form'

import { ValidDaysOfWeek } from './ValidDaysOfWeek'
import { DateTime } from 'luxon'

export function CalendarInfo({
  calendars,
  hasSchedules,
  currentDay,
  currentDate,
  currentWeekday,
  exceptionDates,
  selectedStop,
  routePattern,
  loading,
  minDate,
  maxDate,
}) {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
  const endDateShortestCalendar =
    calendars !== undefined
      ? Object.values(calendars)
          .map(calendar => DateTime.fromISO(calendar.endDate))
          .reduce((max, date) => (date > max ? date : max))
          .toFormat('dd/MM/yyyy')
      : null

  const filteredWeekdays = {
    monday: ((calendars[currentDate]?.monday || weekdays.includes(currentWeekday)) && hasSchedules) || false,
    tuesday: ((calendars[currentDate]?.tuesday || weekdays.includes(currentWeekday)) && hasSchedules) || false,
    wednesday: ((calendars[currentDate]?.wednesday || weekdays.includes(currentWeekday)) && hasSchedules) || false,
    thursday: ((calendars[currentDate]?.thursday || weekdays.includes(currentWeekday)) && hasSchedules) || false,
    friday: ((calendars[currentDate]?.friday || weekdays.includes(currentWeekday)) && hasSchedules) || false,
    saturday: ((calendars[currentDate]?.saturday || currentWeekday === 'saturday') && hasSchedules) || false,
    sunday: ((calendars[currentDate]?.sunday || currentWeekday === 'sunday') && hasSchedules) || false,
  }

  const simpleWeekdays =
    weekdays.includes(currentWeekday) && hasSchedules
      ? {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
        }
      : currentWeekday === 'saturday' && hasSchedules
        ? {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: true,
            sunday: false,
          }
        : currentWeekday === 'saturday' && hasSchedules
          ? {
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
              sunday: true,
            }
          : {
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
              sunday: false,
            }

  const currentDayException = Object.values(exceptionDates.dates).some(dateString => {
    const fullCurrentDay = currentDay.toISO({ includeOffset: false }) + 'Z'
    const datePortion = dateString.slice(0, 10)
    return datePortion === fullCurrentDay.slice(0, 10)
  })

  if (loading) {
    return (
      <Flex direction='column' padding={{ sm: '16px', lg: '16px 24px' }} gap={{ sm: '24px', lg: '32px' }} w='100%'>
        <Flex direction='column' gap='16px' w='100%'>
          <Flex direction={{ sm: 'column', lg: 'row' }} gap='8px' justify='space-between' w='100%'>
            <Flex w={{ sm: '100%', lg: '260px' }}>
              <Controller
                name='date'
                control={control}
                rules={{ required: t('authentication.required_field') }}
                render={({ field }) => (
                  <FormItem required label={''} error={errors.date}>
                    <DateInput
                      {...field}
                      background={'white'}
                      minDate={DateTime.now().startOf('day')}
                      maxDate={maxDate}
                    />
                  </FormItem>
                )}
              />
            </Flex>
            <Text fontSize='12px' color='#475467'>
              {t('network_schedule.schedules_disclaimer')}
            </Text>
          </Flex>
        </Flex>
        <Flex display={{ sm: 'none', lg: 'flex' }} direction='column' gap='16px'>
          {currentDayException && (
            <>
              <Skeleton w='50%' h='19.5px' />
              <Skeleton w='120px' h='24px' />
            </>
          )}
          {!currentDayException && (
            <>
              <Skeleton w='50%' h='19.5px' />
              <Skeleton w='80%' h='36px' />
            </>
          )}
        </Flex>
      </Flex>
    )
  } else {
    return (
      <Flex direction='column' padding={{ sm: '16px', lg: '16px 24px' }} gap={{ sm: '24px', lg: '32px' }} w='100%'>
        <Flex direction='column' gap='16px'>
          <Flex direction={{ sm: 'column', lg: 'row' }} gap='8px' justify='space-between' w='100%'>
            <Flex w={{ sm: '100%', lg: '260px' }}>
              <Controller
                name='date'
                control={control}
                rules={{ required: t('authentication.required_field') }}
                render={({ field }) => (
                  <FormItem required label={''} error={errors.date}>
                    <DateInput
                      {...field}
                      background={'white'}
                      minDate={DateTime.now().startOf('day')}
                      maxDate={maxDate}
                    />
                  </FormItem>
                )}
              />
            </Flex>
            <Text fontSize='12px' color='#475467'>
              {t('network_schedule.schedules_disclaimer')}
            </Text>
          </Flex>
        </Flex>
        <Flex direction='column' gap='16px'>
          {currentDayException && (
            <>
              <Text fontSize='13px' fontWeight='700' color='#475467'>
                {t('network_schedule.exceptional_services')}
              </Text>
              <Label label={t('network_schedule.exception_date')} style={'solid'} type={'neutralDark'} />
            </>
          )}
          {!currentDayException && (
            <>
              <Text fontSize='13px' fontWeight='700' color='#475467'>
                {t('network_schedule.information_displayed_up_to_date', { endDateShortestCalendar })}
              </Text>
              <ValidDaysOfWeek
                routePattern={routePattern}
                weekdays={selectedStop ? simpleWeekdays : filteredWeekdays}
                exceptionDates={Object.values(exceptionDates.dates)}
              />
            </>
          )}
        </Flex>
      </Flex>
    )
  }
}
