import { Box, Flex, Grid, Image, Spinner, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import config from '../../config/configLoader'

import { agencyID, getNetworkInfo } from '../../api/requests'
import { customLog } from '../../helpers/log'
import { Controller, useForm } from 'react-hook-form'
import { SearchInput } from '@ubirider/pick-component-library'
import { Link, useLocation } from 'react-router-dom'
import { AlertCard } from '../Home/components/AlertSection/AlertCard'
import trafficLight from '../../assets/traffic_light.svg'

function NotificationCenter() {
  const location = useLocation()
  const { t } = useTranslation()

  const { control } = useForm({
    defaultValues: {
      search: '',
    },
  })

  const [searchValue, setSearchValue] = useState('')
  const [alerts, setAlerts] = useState(null)
  const [filteredAlerts, setFilteredAlerts] = useState(null)
  const [criticalAlerts, setCriticalAlerts] = useState(null)
  const [warningAlerts, setWarningAlerts] = useState(null)
  const [informativeAlerts, setInformativeAlerts] = useState(null)
  const [criticalFilteredAlerts, setCriticalFilteredAlerts] = useState(null)
  const [warningFilteredAlerts, setWarningFilteredAlerts] = useState(null)
  const [informativeFilteredAlerts, setInformativeFilteredAlerts] = useState(null)
  const [network, setNetwork] = useState(null)
  const [done, setDone] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getInfo = async () => {
      setLoading(true)
      const alertsData = await getNetworkInfo('alerts/getAlerts', { agencyID: agencyID })
      const networkData = await getNetworkInfo('network/getNetwork', { agencyID: agencyID })
      const sortedArray = Object.values(alertsData.entities).sort((a, b) => b.id - a.id)
      const criticalAlerts = sortedArray.filter(alert => alert.alertLevel === 2)
      const warningAlerts = sortedArray.filter(alert => alert.alertLevel === 1)
      const informativeAlerts = sortedArray.filter(alert => alert.alertLevel === 0)
      setAlerts(sortedArray)
      setCriticalAlerts(criticalAlerts)
      setWarningAlerts(warningAlerts)
      setInformativeAlerts(informativeAlerts)
      setNetwork(networkData)
      setDone(true)
      setLoading(false)
      customLog(sortedArray)
    }

    window.scrollTo(0, 0)
    if (!done) {
      getInfo()
    }
  }, [])

  useEffect(() => {
    if (searchValue) {
      const searchAlerts = alerts.filter(alert => {
        const routeNumbers = alert?.affectedRoutes
          ? alert?.affectedRoutes.map(route =>
              network.routes[route?.routeID - 1].shortName !== ''
                ? network.routes[route?.routeID - 1].shortName.toLowerCase()
                : null
            )
          : null
        const routeNames = alert?.affectedRoutes
          ? alert?.affectedRoutes.map(route => network.routes[route?.routeID - 1]?.name.toLowerCase())
          : null
        const stopNames = alert?.affectedStops
          ? alert?.affectedStops.map(route => network.stops[route?.routeID - 1]?.name.toLowerCase())
          : null
        if (
          routeNumbers?.some(str => str?.includes(searchValue.toLowerCase())) ||
          routeNames?.some(str => str?.includes(searchValue.toLowerCase())) ||
          stopNames?.some(str => str?.includes(searchValue.toLowerCase())) ||
          alert?.affectedTransports
        ) {
          return alert
        }
        return null
      })
      const criticalAlerts = searchAlerts.filter(alert => alert.alertLevel === 2)
      const warningAlerts = searchAlerts.filter(alert => alert.alertLevel === 1)
      const informativeAlerts = searchAlerts.filter(alert => alert.alertLevel === 0)
      setFilteredAlerts(searchAlerts)
      setCriticalFilteredAlerts(criticalAlerts)
      setWarningFilteredAlerts(warningAlerts)
      setInformativeFilteredAlerts(informativeAlerts)
    }
  }, [searchValue])

  return (
    <Flex direction='column' flex='1' w='100%'>
      <Flex
        direction='column'
        gap={{ sm: '32px', md: '32px', lg: '60px', xl: '60px' }}
        padding={{ sm: '48px 24px', md: '48px 24px', lg: '90px 70px', xl: '90px 130px' }}
        bg='#F9F9FB'
        align='center'
        justify='center'
      >
        <Flex direction='column' gap='16px'>
          <Text
            fontSize={{ sm: '24px', md: '24px', lg: '30px', xl: '30px' }}
            fontWeight='700'
            color='#002239'
            textAlign='center'
          >
            {t('home.notification_center_title')}
          </Text>
          <Text
            fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
            fontWeight='600'
            color='#282828'
            textAlign='center'
          >
            {t('home.notification_center_subtitle')}
          </Text>
        </Flex>

        {done && alerts.length > 0 && (
          <Flex w={{ sm: '100%', lg: '440px' }} padding={{ sm: '16px 16px 0px 16px', lg: '32px 32px 0px 32px' }}>
            <Controller
              name='search'
              control={control}
              render={({ field: { value, onChange } }) => (
                <SearchInput
                  value={searchValue}
                  onChange={e => {
                    onChange(e)
                    setSearchValue(e)
                  }}
                  placeholder={t('network_schedule.search_route')}
                  css={{ textAlign: 'left' }}
                  background={'white'}
                />
              )}
            />
          </Flex>
        )}

        {!done && (
          <Flex
            direction='column'
            align='center'
            justify='center'
            gap={{ sm: '48px', md: '48px', lg: '60px', xl: '60px' }}
            h='100%'
          >
            <Flex align='center' justify='center' minW='100%' h='100%'>
              <Spinner thickness='5px' color={config.mainColor} width='50px' height='50px' />
            </Flex>
          </Flex>
        )}

        {done && alerts.length === 0 && (
          <Flex
            direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
            align='center'
            justify='center'
            gap='20px'
            h='100%'
          >
            <Image src={trafficLight} width={{ sm: '160px', md: '160px', lg: '186px', xl: '226px' }} />
            <Text
              fontSize={{ sm: '18px', md: '18px', lg: '30px', xl: '30px' }}
              fontWeight='700'
              color='#001D20'
              textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }}
            >
              {t('home.no_alerts')}
            </Text>
          </Flex>
        )}

        {done && alerts.length > 0 && (
          <Flex w='100%' direction='column' justify='center' gap='16px'>
            <Flex
              w={{ sm: '100%', lg: '100%' }}
              borderBottom='2px solid #EAECF0'
              gap='16px'
              justify='center'
              align='center'
              display={{ sm: 'none', lg: 'flex' }}
            >
              <Link
                className='chakra-custom-link'
                to={
                  searchValue === ''
                    ? alerts.length > 0
                      ? '/notification-center'
                      : location.pathname
                    : filteredAlerts?.length > 0
                      ? '/notification-center/warning-alerts'
                      : location.pathname
                }
                style={{
                  cursor:
                    searchValue === ''
                      ? alerts.length > 0
                        ? 'pointer'
                        : 'not-allowed'
                      : filteredAlerts?.length > 0
                        ? 'pointer'
                        : 'not-allowed',
                }}
              >
                <Flex align='center' justify='center' padding='8px 24px 0 24px' gap='10px' direction='column' mb='-2px'>
                  <Flex gap='8px' align='center' justify='center'>
                    <Text
                      fontSize='14px'
                      fontWeight='600'
                      color={location.pathname === '/notification-center' ? config.mainColor : '#98A2B3'}
                    >
                      {t('home.all')}
                    </Text>
                    <Box
                      bg={location.pathname === '/notification-center' ? config.mainColor : '#98A2B3'}
                      padding='2px 8px'
                      rounded='2px'
                    >
                      <Text fontSize='14px' fontWeight='600' color='white'>
                        {searchValue === '' ? alerts.length : filteredAlerts?.length || '0'}
                      </Text>
                    </Box>
                  </Flex>
                  <Box
                    display={location.pathname === '/notification-center' ? 'flex' : 'none'}
                    h='2px'
                    w='32px'
                    bg={config.mainColor}
                    rounded='8px'
                  />
                </Flex>
              </Link>
              <Link
                className='chakra-custom-link'
                to={
                  searchValue === ''
                    ? criticalAlerts.length > 0
                      ? '/notification-center/critical-alerts'
                      : location.pathname
                    : criticalFilteredAlerts?.length > 0
                      ? '/notification-center/critical-alerts'
                      : location.pathname
                }
                style={{
                  cursor:
                    searchValue === ''
                      ? criticalAlerts.length > 0
                        ? 'pointer'
                        : 'not-allowed'
                      : criticalFilteredAlerts?.length > 0
                        ? 'pointer'
                        : 'not-allowed',
                }}
              >
                <Flex align='center' justify='center' padding='8px 24px 0 24px' gap='10px' direction='column' mb='-2px'>
                  <Flex gap='8px' align='center' justify='center'>
                    <Text
                      fontSize='14px'
                      fontWeight='600'
                      color={location.pathname.includes('/critical-alerts') ? config.mainColor : '#98A2B3'}
                    >
                      {t('home.critical_alerts')}
                    </Text>
                    <Box
                      bg={location.pathname.includes('/critical-alerts') ? config.mainColor : '#98A2B3'}
                      padding='2px 8px'
                      rounded='2px'
                    >
                      <Text fontSize='14px' fontWeight='600' color='white'>
                        {searchValue === '' ? criticalAlerts.length : criticalFilteredAlerts?.length || '0'}
                      </Text>
                    </Box>
                  </Flex>
                  <Box
                    display={location.pathname.includes('/critical-alerts') ? 'flex' : 'none'}
                    h='2px'
                    w='32px'
                    bg={config.mainColor}
                    rounded='8px'
                  />
                </Flex>
              </Link>
              <Link
                className='chakra-custom-link'
                to={
                  searchValue === ''
                    ? warningAlerts.length > 0
                      ? '/notification-center/warning-alerts'
                      : location.pathname
                    : warningFilteredAlerts?.length > 0
                      ? '/notification-center/warning-alerts'
                      : location.pathname
                }
                style={{
                  cursor:
                    searchValue === ''
                      ? warningAlerts.length > 0
                        ? 'pointer'
                        : 'not-allowed'
                      : warningFilteredAlerts?.length > 0
                        ? 'pointer'
                        : 'not-allowed',
                }}
              >
                <Flex align='center' justify='center' padding='8px 24px 0 24px' gap='10px' direction='column' mb='-2px'>
                  <Flex gap='8px' align='center' justify='center'>
                    <Text
                      fontSize='14px'
                      fontWeight='600'
                      color={location.pathname.includes('/warning-alerts') ? config.mainColor : '#98A2B3'}
                    >
                      {t('home.warning_alerts')}
                    </Text>
                    <Box
                      bg={location.pathname.includes('/warning-alerts') ? config.mainColor : '#98A2B3'}
                      padding='2px 8px'
                      rounded='2px'
                    >
                      <Text fontSize='14px' fontWeight='600' color='white'>
                        {searchValue === '' ? warningAlerts.length : warningFilteredAlerts?.length || '0'}
                      </Text>
                    </Box>
                  </Flex>
                  <Box
                    display={location.pathname.includes('/warning-alerts') ? 'flex' : 'none'}
                    h='2px'
                    w='32px'
                    bg={config.mainColor}
                    rounded='8px'
                  />
                </Flex>
              </Link>
              <Link
                className='chakra-custom-link'
                to={
                  searchValue === ''
                    ? informativeAlerts.length > 0
                      ? '/notification-center/informative-alerts'
                      : location.pathname
                    : informativeFilteredAlerts?.length > 0
                      ? '/notification-center/informative-alerts'
                      : location.pathname
                }
                style={{
                  cursor:
                    searchValue === ''
                      ? informativeAlerts.length > 0
                        ? 'pointer'
                        : 'not-allowed'
                      : informativeFilteredAlerts?.length > 0
                        ? 'pointer'
                        : 'not-allowed',
                }}
              >
                <Flex align='center' justify='center' padding='8px 24px 0 24px' gap='10px' direction='column' mb='-2px'>
                  <Flex gap='8px' align='center' justify='center'>
                    <Text
                      fontSize='14px'
                      fontWeight='600'
                      color={location.pathname.includes('/informative-alerts') ? config.mainColor : '#98A2B3'}
                    >
                      {t('home.informative_alerts')}
                    </Text>
                    <Box
                      bg={location.pathname.includes('/informative-alerts') ? config.mainColor : '#98A2B3'}
                      padding='2px 8px'
                      rounded='2px'
                    >
                      <Text fontSize='14px' fontWeight='600' color='white'>
                        {searchValue === '' ? informativeAlerts.length : informativeFilteredAlerts?.length || '0'}
                      </Text>
                    </Box>
                  </Flex>
                  <Box
                    display={location.pathname.includes('/informative-alerts') ? 'flex' : 'none'}
                    h='2px'
                    w='32px'
                    bg={config.mainColor}
                    rounded='8px'
                  />
                </Flex>
              </Link>
            </Flex>

            <Flex>
              <Grid
                templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }}
                overflowX={{ sm: 'scroll', md: 'none' }}
                w='100%'
                gap='16px'
                padding='16px'
                sx={{ '::-webkit-scrollbar': { display: 'none' } }}
                placeItems={alerts?.length === 1 ? 'center' : { sm: 'center', md: undefined }}
              >
                {searchValue !== '' &&
                  filteredAlerts?.map(alert => (
                    <AlertCard key={alert.id} alert={alert} network={network} hasDescription hasLink />
                  ))}
                {location.pathname === '/notification-center' &&
                  searchValue === '' &&
                  alerts?.map(alert => <AlertCard key={alert.id} alert={alert} network={network} hasDescription />)}
                {location.pathname.includes('/critical-alerts') &&
                  searchValue === '' &&
                  criticalAlerts?.map(alert => (
                    <AlertCard key={alert.id} alert={alert} network={network} hasDescription />
                  ))}
                {location.pathname.includes('/warning-alerts') &&
                  searchValue === '' &&
                  warningAlerts?.map(alert => (
                    <AlertCard key={alert.id} alert={alert} network={network} hasDescription />
                  ))}
                {location.pathname.includes('/informative-alerts') &&
                  searchValue === '' &&
                  informativeAlerts?.map(alert => (
                    <AlertCard key={alert.id} alert={alert} network={network} hasDescription />
                  ))}
              </Grid>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default NotificationCenter
