import { Flex, Image, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconWithinCircle } from '../../pages/Common/IconWithinCircle'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie/es6'

import deleteImage from '../../assets/cross_delete.svg'
import closeButton from '../../assets/cross.svg'

export const CancelModal = props => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const cookies = new Cookies()

  function handleClose() {
    //localStorage.removeItem('PassRequestData')
    cookies.remove('PassRequestData')
    props.onClose()
    props.onCloseOrderPass()
    if (window.location.pathname.includes('/ticket-fares')) {
      navigate('/ticket-fares')
    } else if (window.location.pathname.includes('/pass-migration')) {
      navigate('/pass-migration')
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size={{ sm: 'xs', md: 'xs', lg: '', xl: '' }}
      closeOnOverlayClick={false}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        borderRadius='8px'
        maxW='600px'
        padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}
      >
        <Flex direction='row' justifyContent='flex-end' alignItems='center'>
          <Flex as='button' onClick={props.onClose}>
            <Image src={closeButton} />
          </Flex>
        </Flex>
        <Flex direction='column' gap='48px' align='center'>
          <Flex direction='column' gap='16px' align='center'>
            <IconWithinCircle color='danger.600' icon={deleteImage} />
            <Flex direction='column' gap='8px' align='center'>
              <Text
                fontWeight='700'
                fontSize={{ sm: '20px', md: '20px', lg: '24px', xl: '24px' }}
                color='neutral.800'
                align='center'
              >
                {t('ticket_fares.cancel_request_title')}
              </Text>
              <Text
                fontWeight='600'
                fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
                color='neutral.600'
                align='center'
              >
                {t('authentication.lose_information')}
              </Text>
            </Flex>
          </Flex>

          <Flex gap='16px' display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}>
            <UbiButton
              onClick={handleClose}
              loading={props.loading}
              style='solid'
              type='danger'
              label={t('ticket_fares.cancel_request_exit')}
              css={{ minWidth: '182px' }}
            />
            <UbiButton
              onClick={props.onClose}
              style='outline'
              type='neutralDark'
              label={t('authentication.back_to_order')}
              css={{ minWidth: '182px' }}
            />
          </Flex>
          <Flex
            gap='16px'
            alignSelf='stretch'
            direction='column'
            display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}
          >
            <UbiButton
              onClick={handleClose}
              loading={props.loading}
              style='solid'
              type='danger'
              label={t('ticket_fares.cancel_request_exit')}
              css={{ width: '100%' }}
            />
            <UbiButton
              onClick={props.onClose}
              style='outline'
              type='neutralDark'
              label={t('authentication.back_to_order')}
              css={{ width: '100%' }}
            />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
