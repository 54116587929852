import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { SearchWidget } from '../Home/components/MainHeader/SearchWidget'
import { Map, useLoadMap } from '@ubirider/pick-component-library'
import { TripCard } from './TripCard'
import { useParams } from 'react-router-dom'

function SearchResults() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const params = useParams()
  const origin = params.o
  const destination = params.d
  const date = params.t
  const passengers = params.p

  const { isLoaded: isSearchMapLoaded } = useLoadMap()

  return (
    <>
      <Flex
        direction='column'
        padding={{ sm: '16px 24px', md: '16px 24px', lg: '30px 130px', xl: '30px 130px' }}
        flex='1'
        bg='#98A2B3'
        gap='8px'
      >
        <SearchWidget start={origin} end={destination} time={date} people={passengers} />
        <Text fontSize='14px' fontWeight='600' color='white' textAlign={{ sm: 'center', lg: 'left' }}>
          {t('home.weekday_schedules')}
        </Text>
      </Flex>

      {isSearchMapLoaded && (
        <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} w='100%'>
          <Map
            bounds={null}
            css={{ width: '100%', border: 'unset !important', borderRadius: 'unset !important' }}
          ></Map>
        </Flex>
      )}

      <Flex
        direction='column'
        gap='60px'
        padding={{ sm: '24px 32px', md: '24px 32px', lg: '90px 130px', xl: '90px 130px' }}
        align='center'
        justify='center'
        w='100%'
      >
        <Flex direction='column' gap='16px' align='center'>
          <Text fontSize='30px' fontWeight='700' color='#006644'>
            {t('widget_fare_search.choose_departure_title')}
          </Text>
          <Text>{t('widget_fare_search.choose_departure_subtitle')}</Text>
        </Flex>

        <TripCard />
      </Flex>
    </>
  )
}

export default SearchResults
