import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

function PassengerRights() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex direction='column' flex='1' bg='#F9F9FB'>
      <Flex
        direction='column'
        gap={{ sm: '48px', md: '48px', lg: '80px', xl: '80px' }}
        padding={{ sm: '32px 24px', md: '32px 24px', lg: '100px 130px', xl: '100px 130px' }}
        align='center'
        width='100%'
      >
        <Flex direction='column' align='center' justify='center'>
          <Text fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }} fontWeight='700' color='#001D20'>
            {t('passenger_rights.passengers_rights_title')}
          </Text>
        </Flex>

        <Flex
          w='100%'
          direction='column'
          gap='48px'
          padding={{ sm: '20px', md: '20px', lg: '40px', xl: '40px' }}
          align='flex-start'
          justify='center'
          bg='white'
          border='1px solid #D3D3D3'
          borderRadius='8px'
          flexWrap='wrap'
        >
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
            {t('passenger_rights.passengers_rights_description_1')}
          </Text>
          <Flex direction='column' gap='16px'>
            <Flex direction='column'>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_2')}
              </Text>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_3')}
              </Text>
            </Flex>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_4')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_5')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_6')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_7')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_8')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_9')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_10')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_11')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_12')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_13')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_14')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_15')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_16')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_17')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_18')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_19')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_20')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_21')}
            </Text>
          </Flex>
          <Flex direction='column' gap='16px'>
            <Flex direction='column'>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_22')}
              </Text>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_23')}
              </Text>
            </Flex>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_24')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_25')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_26')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_27')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_28')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_29')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_30')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_31')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_32')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_33')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_34')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_35')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_36')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_37')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_38')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_39')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_40')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_41')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_42')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_43')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_44')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_45')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_46')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_47')}
            </Text>
          </Flex>
          <Flex direction='column' gap='16px'>
            <Flex direction='column'>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_48')}
              </Text>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_49')}
              </Text>
            </Flex>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_50')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_51')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_52')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_53')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_54')}
            </Text>
          </Flex>
          <Flex direction='column' gap='16px'>
            <Flex direction='column'>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_55')}
              </Text>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_56')}
              </Text>
            </Flex>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_57')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_58')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_59')}
            </Text>
          </Flex>
          <Flex direction='column' gap='16px'>
            <Flex direction='column'>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_60')}
              </Text>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_61')}
              </Text>
            </Flex>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_62')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_63')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_64')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_65')}
            </Text>
          </Flex>
          <Flex direction='column' gap='16px'>
            <Flex direction='column'>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_66')}
              </Text>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_67')}
              </Text>
            </Flex>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_68')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_69')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_70')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_71')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_72')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_73')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_74')}
            </Text>
          </Flex>
          <Flex direction='column' gap='16px'>
            <Flex direction='column'>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_75')}
              </Text>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_76')}
              </Text>
            </Flex>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_77')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_78')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_79')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_80')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_81')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_82')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_83')}
            </Text>
          </Flex>
          <Flex direction='column' gap='16px'>
            <Flex direction='column'>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_84')}
              </Text>
              <Text
                fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                fontWeight='700'
                color='#282828'
                maxW='100%'
              >
                {t('passenger_rights.passengers_rights_description_85')}
              </Text>
            </Flex>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_86')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_87')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('passenger_rights.passengers_rights_description_88')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PassengerRights
