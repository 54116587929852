import { Flex, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { RouteLabel } from '@ubirider/pick-component-library'
import React from 'react'

export function AffectedRoutesComponent(props) {
  const { t } = useTranslation()

  return (
    <>
      <Text fontSize='14px' fontWeight='600' color='#1D2939'>
        {t('network_schedule.routes')}
      </Text>
      <Wrap spacing={2}>
        {props.alert?.affectedRoutes?.map((route, i) => (
          <WrapItem key={i}>
            <RouteLabel
              shortName={props?.network?.routes[route?.routeID - 1].shortName}
              color={props?.network?.routes?.find(obj => obj.id === route.routeID)?.color || '#1D2939'}
              textColor={props?.network?.routes.find(obj => obj.id === route.routeID)?.textColor || 'white'}
            />
            <Flex
              padding='1px 8px 1.5px 12px'
              ml='-4px'
              borderTop='1px solid rgba(71, 84, 103, 0.40)'
              borderRight='1px solid rgba(71, 84, 103, 0.40)'
              borderBottom='1px solid rgba(71, 84, 103, 0.40)'
              rounded='0 4px 4px 0'
              align='center'
            >
              <Text fontSize='13px' fontWeight='600' color='#1D2939'>
                {props.network.routes[route?.routeID - 1].name
                  .toLowerCase()
                  .replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
              </Text>
            </Flex>
          </WrapItem>
        ))}
      </Wrap>
    </>
  )
}
