import { MapMarker, RealtimeIcon, RouteLabel } from '@ubirider/pick-component-library'
import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

import busGreen from '../../../../../../assets/bus_green.png'
import busYellow from '../../../../../../assets/bus_yellow.png'
import busRed from '../../../../../../assets/bus_red.png'

function RealTimeBusMarker({ network, selectedPattern, selectedStop }) {
  return (
    <>
      {network?.real_time.map((bus, i) => (
        <Flex key={i}>
          <MapMarker
            position={{ lat: bus.position.lat, lng: bus.position.lng }}
            iconUrl={
              bus.stops[0].diff < -60 || bus.stops[0].diff > 600
                ? busRed
                : bus.stops[0].diff > 180
                  ? busYellow
                  : busGreen
            }
            size={{ width: 36, height: 36 }}
            visible={
              bus.patternID === network.route.patterns.ids[selectedPattern] ||
              bus.stops.some(stop => stop.stopID === selectedStop)
            }
            zIndex={8500}
            popover={
              <Flex direction='column' borderRadius='8px' gap='8px' zIndex='7500' padding='5px'>
                <Flex justify='space-between' gap='16px' align='flex-start'>
                  <Flex gap='16px' align='center' maxW='300px'>
                    <RouteLabel
                      shortName={network.route.shortName}
                      color={network.route.color}
                      textColor={network.route.textColor}
                    />
                    <Flex>
                      <RealtimeIcon size={'verySmall'} />
                      <Text fontSize='13px' fontWeight='600' color='#424D5F'>
                        {bus.stops[0].diff < 90 ? '1' : Math.floor(bus.stops[0].diff / 60)} min
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            }
          />
        </Flex>
      ))}
    </>
  )
}

export default RealTimeBusMarker
