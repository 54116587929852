import {
  Flex,
  Image, Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import {Button as UbiButton} from "@ubirider/pick-component-library";
import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import config from "../../config/configLoader";

import shield from "../../assets/shield.svg";
import closeButton from "../../assets/cross.svg";
import {getCheckoutURL} from "../../api/api";
import {Link as ReactLink} from "react-router-dom";

export const RedirectModal = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false)

  function handleRedirect() {
    setLoading(true)
    window.location.replace(getCheckoutURL() + "?ref=" + props.orderCode + `&color=${config.mainColor.slice(1)}`)
    setLoading(false)
    props.onClose()
  }

  return (
      <Modal
          isOpen={props.isOpen}
          onClose={props.onClose}
          size={{sm: "xs", md: "xs", lg: "", xl: ""}}
          trapFocus={false}
          closeOnOverlayClick={false}
          autoFocus={false}
          isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius="8px" maxW="600px" padding={{sm: "16px 24px", md: "16px 24px", lg: "36px", xl: "36px"}}>
          <Flex direction="row" justifyContent="flex-end" alignItems="center">
            <Flex as="button" onClick={props.onClose}>
              <Image src={closeButton} />
            </Flex>
          </Flex>
          <Flex direction="column" gap="48px" align="center">
              <Flex direction="column" gap="16px" align="center">
                <Image src={shield} />
                <Flex direction="column" gap="8px" align="center">
                  <Text
                      fontWeight="700"
                      fontSize={{sm: "20px", md: "20px", lg: "24px", xl: "24px"}}
                      color="neutral.800"
                      align="center"
                  >
                    {t("ticket_fares.payment_partner_redirect_title")}
                  </Text>
                  <Text
                      fontWeight="600"
                      fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}}
                      color="neutral.600"
                      align="center"
                  >
                    {t("ticket_fares.payment_partner_redirect_subtitle")}
                  </Text>
                </Flex>
              </Flex>

              <Flex gap="16px" display={{sm: "none", md: "none", lg: "flex", xl: "flex"}}>
                <UbiButton
                    onClick={props.onClose}
                    style="outline"
                    type="neutralDark"
                    label={t("common.back")}
                    css={{width: "152px"}}
                />
                <UbiButton
                    onClick={handleRedirect}
                    loading={loading}
                    style="solid"
                    type="primary"
                    label={t("common.continue")}
                    css={{width: "152px"}}
                />
              </Flex>
              <Flex gap="16px" alignSelf="stretch" direction="column" display={{sm: "flex", md: "flex", lg: "none", xl: "none"}}>
                <UbiButton
                    onClick={handleRedirect}
                    loading={loading}
                    style="solid"
                    type="primary"
                    label={t("common.continue")}
                    css={{width: "100%"}}
                />
                <UbiButton
                    onClick={props.onClose}
                    style="outline"
                    type="neutralDark"
                    label={t("common.back")}
                    css={{width: "100%"}}
                />
              </Flex>
            <Text fontSize="14px" color="#475467">
              <Trans i18nKey="ticket_fares.payment_partner_redirect_disclaimer">
                To learn more about how we use your data consult our FAQS and the privacy policies of our partner: <Link className="chakra-custom-link" sx={{ textDecoration: 'underline', fontWeight: '600'}} as={ReactLink} to="https://www.vivawallet.com/gb_en/privacy-policy" isExternal>Viva Wallet</Link>.
              </Trans>
            </Text>
            </Flex>
        </ModalContent>
      </Modal>
  );
};
