import { Flex, Text, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { Button } from '@ubirider/pick-component-library'
import React from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from '@chakra-ui/react'
import Cookies from 'universal-cookie/es6'

export const CookiePermissionModal = props => {
  const { t } = useTranslation()
  const cookies = new Cookies()

  const acceptAll = () => {
    cookies.set('CookieConsent', 'all')
    props.onClose()
  }

  const rejectFunctional = () => {
    cookies.set('CookieConsent', 'essential')
    props.onClose()
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      motionPreset={'slideInBottom'}
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay backdropFilter='auto' />
      <ModalContent
        position='absolute'
        bottom='0'
        mb='0'
        minWidth='100vw'
        height={{ sm: 'calc(100% - 70px)', lg: 'fit-content' }}
        padding={{ sm: '32px 24px', lg: '48px' }}
        borderRadius='4px 4px 0 0'
        box-shadow='0px 2px 20px 1px rgba(0, 0, 0, 0.10)'
        gap='48px'
      >
        <Flex direction='column' gap='16px' textAlign={{ sm: 'center', lg: 'left' }}>
          <Text fontSize={{ sm: '24px', lg: '18px' }} fontWeight='700'>
            {t('cookie_policy.cookie_consent_management_title')}
          </Text>
          <Text fontSize='16px' color='#475467'>
            <Trans i18nKey='cookie_policy.cookie_consent_management_description'>
              We use cookies and similar devices on our website for its proper technical functioning (essential cookies)
              and to remember your preferences for using the page (functional cookies). You can accept all cookies or
              you can accept only the essential cookies, which are required for you to use our website. By browsing our
              website, you agree to our <Link className='chakra-custom-link' sx={{ textDecoration: 'underline', fontWeight: '600' }} as={ReactLink} to={'/cookie-policy'} isExternal>Cookie Policy</Link>.
            </Trans>
          </Text>
        </Flex>
        <Flex display={{ sm: 'none', lg: 'flex' }} justify='space-between' align='center'>
          <Button
            linkComponent={<ReactLink className='chakra-custom-link' to={'/cookie-policy'} target={'_blank'} />}
            type={'neutralLight'}
            style={'typo'}
            label={t('cookie_policy.know_more')}
          />
          <Flex gap='16px'>
            <Button
              onClick={rejectFunctional}
              type={'neutralLight'}
              style={'solid'}
              label={t('cookie_policy.reject_functional_cookies')}
            />
            <Button onClick={acceptAll} type={'primary'} style={'solid'} label={t('cookie_policy.accept_all')} />
          </Flex>
        </Flex>
        <Flex display={{ sm: 'flex', lg: 'none' }} gap='8px' direction='column' align='center'>
          <Button
            onClick={acceptAll}
            type={'primary'}
            style={'solid'}
            label={t('cookie_policy.accept_all')}
            css={{ width: '100%' }}
          />
          <Button
            onClick={rejectFunctional}
            type={'neutralLight'}
            style={'solid'}
            label={t('cookie_policy.reject_functional_cookies')}
            css={{ width: '100%' }}
          />
          <Button
            linkComponent={<ReactLink className='chakra-custom-link' to={'/cookie-policy'} target={'_blank'} />}
            type={'neutralLight'}
            style={'typo'}
            label={t('cookie_policy.know_more')}
          />
        </Flex>
      </ModalContent>
    </Modal>
  )
}
