import { Flex } from '@chakra-ui/react'
import { ButtonDropdown, DropdownList } from '@ubirider/pick-component-library'
import React, { useState } from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { TagDay } from './TagDay'
import { DateTime } from 'luxon'
import { useFormContext } from 'react-hook-form'

export function ValidDaysOfWeek({ weekdays, exceptionDates, routePattern }) {
  const { t } = useTranslation()

  const { reset } = useFormContext()

  const [visibleDates, setVisibleDates] = useState(false)
  const [selectedDate, selectDate] = useState(null)

  const formatDate = date => {
    return DateTime.fromISO(date, { locale: i18next.language })
      .toFormat('EEE, dd LLL, yyyy')
      .replace(/\b\w/g, match => match.toUpperCase())
      .replace(/\./g, '')
  }

  const handleExceptionDateSelect = exceptionDate => {
    setVisibleDates(false)
    selectDate(exceptionDate)
    reset({
      date: DateTime.fromISO(exceptionDate),
      pattern: routePattern,
    })
  }

  const cells = Array(exceptionDates.length)
    .fill()
    .map((_, i) => ({
      label: formatDate(exceptionDates[i]),
      onClick: () => handleExceptionDateSelect(exceptionDates[i]),
      selected: selectedDate === exceptionDates[i],
    }))

  return (
    <Flex direction={{ sm: 'column', lg: 'row' }} gap='16px' align='center'>
      <Flex gap='8px' align={{ sm: 'center', lg: 'flex-start' }}>
        {Object.keys(weekdays).map(day => (
          <TagDay day={day} isTrue={weekdays[day]} />
        ))}
      </Flex>
      {exceptionDates.length > 0 && (
        <>
          <Flex display={{ sm: 'none', lg: 'flex' }} w='1px' h='33px' bg='#D0D5DD'></Flex>
          <Flex direction='column' gap='8px' align='center'>
            <Flex display={{ sm: 'none', lg: 'flex' }}>
              <ButtonDropdown
                buttonProps={{
                  type: 'neutralLight',
                  style: 'outline',
                  size: 'small',
                  label: t('network_schedule.view_exception_dates'),
                }}
                cells={cells}
                placement={'bottom'}
                dropdownWidth={256}
              />
            </Flex>
            <Flex display={{ sm: 'flex', lg: 'none' }} w='100%'>
              <ButtonDropdown
                buttonProps={{
                  type: 'neutralLight',
                  style: 'outline',
                  size: 'small',
                  label: t('network_schedule.view_exception_dates'),
                }}
                cells={cells}
                placement={'bottom'}
                dropdownWidth={'100%'}
                css={{ width: '100%' }}
              />
            </Flex>
            {visibleDates && (
              <Flex position='fixed' display='flex' marginTop='44px' zIndex='5'>
                <DropdownList cells={cells} css={{ width: '256px', maxHeight: '256px' }} />
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Flex>
  )
}
