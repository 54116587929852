import { Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import config from '../../../../../../config/configLoader'
import React from 'react'
import bikeNoIcon from '../../../../../../assets/no_bike.svg'
import bikeYesIcon from '../../../../../../assets/bike_yes.svg'
import wheelchairNoIcon from '../../../../../../assets/no_wheelchair.svg'
import wheelchairYesIcon from '../../../../../../assets/wheelchair_yes.svg'

function RouteUtilitiesInfo({ selectedNetwork }) {
  const { t } = useTranslation()

  return (
    <>
      {config.operator !== 'trevo' && (
        <Flex direction='column' gap='8px'>
          {selectedNetwork.route?.bikesAllowed === 0 && (
            <Flex gap='8px'>
              <Image src={bikeNoIcon} />
              <Text fontSize='14px' fontWeight='600' color='#475467'>
                {t('network_schedule.bikes_not_allowed')}
              </Text>
            </Flex>
          )}
          {selectedNetwork.route?.bikesAllowed !== 0 && (
            <Flex gap='8px'>
              <Image src={bikeYesIcon} />
              <Text fontSize='14px' fontWeight='600' color='#475467'>
                {t('network_schedule.bikes_allowed')}
              </Text>
            </Flex>
          )}
          {selectedNetwork.route?.wheelchairAccessible === 0 && (
            <Flex gap='8px'>
              <Image src={wheelchairNoIcon} />
              <Text fontSize='14px' fontWeight='600' color='#475467'>
                {t('network_schedule.wheelchair_not_allowed')}
              </Text>
            </Flex>
          )}
          {selectedNetwork.route?.wheelchairAccessible !== 0 && (
            <Flex gap='8px'>
              <Image src={wheelchairYesIcon} />
              <Text fontSize='14px' fontWeight='600' color='#475467'>
                {t('network_schedule.wheelchair_allowed')}
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </>
  )
}

export default RouteUtilitiesInfo
