import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const TrevoPrivacyPolicy = () => {
  const { t } = useTranslation()

  return (
    <Flex direction='column' flex='1' bg='#F9F9FB'>
      <Flex
        direction='column'
        gap={{ sm: '48px', md: '48px', lg: '80px', xl: '80px' }}
        padding={{ sm: '32px 24px', md: '32px 24px', lg: '100px 130px', xl: '100px 130px' }}
        align='center'
        width='100%'
      >
        <Text
          fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }}
          fontWeight='700'
          color='#001D20'
          align='center'
          justify='center'
        >
          {t('privacy_policy.privacy_policy_title')}
        </Text>

        <Flex
          w='100%'
          direction='column'
          gap='16px'
          padding={{ sm: '20px', md: '20px', lg: '40px', xl: '40px' }}
          align='flex-start'
          justify='center'
          bg='white'
          border='1px solid #D3D3D3'
          borderRadius='8px'
          flexWrap='wrap'
        >
          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
            {t('privacy_policy.privacy_policy_description_1')}
          </Text>
          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_2')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_3')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_4')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_5')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_6')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_7')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_8')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_9')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_10')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_11')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_12')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_13')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_14')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_15')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_16')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_17')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_18')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_19')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_20')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_21')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_22')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_23')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_24')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_25')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_26')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_27')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_28')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_29')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_30')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_31')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_32')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_33')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_34')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_35')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_36')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_37')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_38')}
            </Text>
          </Flex>

          <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
            {t('privacy_policy.privacy_policy_description_39')}
          </Text>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_40')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_41')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_42')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_43')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_44')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_45')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_46')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_47')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_48')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_49')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_50')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_51')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_52')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_53')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_54')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_55')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_56')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_57')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_58')}
            </Text>
          </Flex>
          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_59')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_60')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_61')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_62')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_63')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_64')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_65')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_66')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_67')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_68')}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              fontWeight='700'
              color='#282828'
              maxW='100%'
            >
              {t('privacy_policy.privacy_policy_description_69')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} color='#282828' maxW='100%'>
              {t('privacy_policy.privacy_policy_description_70')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
