import { Flex, Modal, ModalContent, ModalOverlay, Spinner, Text } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createSale, sendTicketingInfo } from '../../api/requests'
import { deleteFileFromDB } from '../../hooks/useIndexedDB'
import check from '../../assets/check_white.svg'
import alert from '../../assets/alert_dark_yellow.svg'
import { IconWithinCircle } from '../../pages/Common/IconWithinCircle'
import { useNavigate } from 'react-router-dom'
import { customError } from '../../helpers/log'
import Cookies from 'universal-cookie/es6'

export const LoadingRequestModal = ({ data, files, id, isOpen, onClose }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const cookies = new Cookies()

  const [loading, setLoading] = useState(0)

  useEffect(() => {
    const handlePassRequests = async () => {
      if (data?.total > 0 && !id) {
        setLoading(-1)
      } else {
        try {
          setLoading(1)
          const resTicketing = await sendTicketingInfo(data, files)
          console.log(resTicketing)
          setLoading(2)
          await createSale(data, resTicketing, id)
          setLoading(3)
          await deleteAllFilesData()
          //localStorage.removeItem('PassRequestData')
          cookies.remove('PassRequestData')
        } catch (error) {
          setLoading(-1)
          customError(error)
        }
      }
    }

    const deleteAllFilesData = async () => {
      try {
        await deleteFileFromDB('photo')
        for (let i = 0; i < data.filesSize; i++) {
          await deleteFileFromDB('file_' + i)
        }
      } catch (error) {
        customError(error)
      }
    }

    if (isOpen) {
      handlePassRequests()
    } else {
      setLoading(0)
    }
  }, [isOpen])

  function handleClose() {
    navigate('/ticket-fares')
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ sm: 'xs', md: 'xs', lg: '', xl: '' }}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        borderRadius='8px'
        maxW='600px'
        padding={{ sm: '48px 24px', md: '48px 24px', lg: '64px 56px', xl: '64px 56px' }}
      >
        <Flex direction='column' gap='32px' align='center'>
          {loading === 0 && <Flex h='264px'></Flex>}
          {loading === 1 && (
            <Flex direction='column' gap='32px' align='center' justify='center' textAlign='center'>
              <Spinner thickness='14px' color='#98A2B3' width='120px' height='120px' />
              <Text fontSize='24px' fontWeight='700' color='#1D2939'>
                {t('ticket_fares.creating_request')}
              </Text>
              <Text fontSize='16px' fontWeight='600' color='1D2939'>
                {t('ticket_fares.wait_a_few_seconds')}
              </Text>
            </Flex>
          )}
          {loading === 2 && (
            <Flex direction='column' gap='32px' align='center' justify='center' textAlign='center'>
              <Spinner thickness='14px' color='#98A2B3' width='120px' height='120px' />
              <Text fontSize='24px' fontWeight='700' color='#1D2939'>
                {t('ticket_fares.processing_information')}
              </Text>
              <Text fontSize='16px' fontWeight='600' color='1D2939'>
                {t('ticket_fares.wait_a_few_seconds')}
              </Text>
            </Flex>
          )}
          {loading === 3 && (
            <Flex direction='column' gap='32px' align='center' justify='center' textAlign='center'>
              <IconWithinCircle color='success.600' icon={check} width='120px' />
              <Text fontSize='24px' fontWeight='700' color='#1D2939'>
                {t('ticket_fares.request_submitted')}
              </Text>
              <Flex gap='16px' display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}>
                <UbiButton
                  onClick={handleClose}
                  style='solid'
                  type='success'
                  label={t('common.ok')}
                  css={{ width: '152px' }}
                />
              </Flex>
              <Flex
                gap='16px'
                alignSelf='stretch'
                direction='column'
                display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}
              >
                <UbiButton
                  onClick={handleClose}
                  style='solid'
                  type='success'
                  label={t('common.ok')}
                  css={{ width: '100%' }}
                />
              </Flex>
            </Flex>
          )}
          {loading === -1 && (
            <Flex direction='column' gap='32px' align='center' justify='center' textAlign='center'>
              <IconWithinCircle color='warning.400' icon={alert} width='120px' />
              <Text fontSize='24px' fontWeight='700' color='#1D2939'>
                {t('ticket_fares.request_failed')}
              </Text>
              <Text fontSize='16px' fontWeight='600' color='1D2939'>
                {t('ticket_fares.request_failed_subtitle')}
              </Text>
              <Flex gap='16px' display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}>
                <UbiButton
                  onClick={handleClose}
                  style='solid'
                  type='warning'
                  label={t('common.ok')}
                  css={{ width: '152px' }}
                />
              </Flex>
              <Flex
                gap='16px'
                alignSelf='stretch'
                direction='column'
                display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}
              >
                <UbiButton
                  onClick={handleClose}
                  style='solid'
                  type='warning'
                  label={t('common.ok')}
                  css={{ width: '100%' }}
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  )
}
