import { useTranslation } from 'react-i18next'
import { Flex, Text, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import pin from '../../../../../assets/pin_green.svg'
import swap from '../../../../../assets/swap.svg'
import user from '../../../../../assets/user.svg'
import {
  Button,
  Calendar,
  DropdownInput,
  DropdownList,
  FloatingComponent,
  Icon,
} from '@ubirider/pick-component-library'
import i18next from 'i18next'
import globeIcon from '../../../../../assets/globe.svg'
import { useState } from 'react'

export function SearchWidget({ start, end, time, people }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [isOpenOrigin, toggleOpenOrigin] = useState(false)
  const [origin, setOrigin] = useState(start || null)
  const [isOpenDestination, toggleOpenDestination] = useState(false)
  const [destination, setDestination] = useState(end || null)
  const [isOpenDate, toggleOpenDate] = useState(false)
  const [date, setDate] = useState(time || null)
  const [isOpenPassengers, toggleOpenPassengers] = useState(false)
  const [passengers, setPassengers] = useState(people || null)

  const stopOptions = [
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          {t('common.english')}
        </Text>
      ),
      value: 'EN',
    },
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          {t('common.portuguese')}
        </Text>
      ),
      value: 'PT',
    },
  ]

  const numberPassengers = [
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          0
        </Text>
      ),
      value: 0,
    },
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          1
        </Text>
      ),
      value: 1,
    },
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          2
        </Text>
      ),
      value: 2,
    },
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          3
        </Text>
      ),
      value: 3,
    },
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          4
        </Text>
      ),
      value: 4,
    },
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          5
        </Text>
      ),
      value: 5,
    },
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          6
        </Text>
      ),
      value: 6,
    },
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          7
        </Text>
      ),
      value: 7,
    },
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          8
        </Text>
      ),
      value: 8,
    },
    {
      label: (
        <Text fontSize='14px' fontWeight='700' color='#424D5F'>
          9
        </Text>
      ),
      value: 9,
    },
  ]

  const handleNavigate = () => {
    navigate(
      `/search/${encodeURIComponent(origin)}/${encodeURIComponent(destination)}/${encodeURIComponent(date)}/${encodeURIComponent(passengers)}`
    )
  }

  return (
    <Flex
      direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
      gap={{ sm: '16px', md: '16px', lg: '0', xl: '0' }}
      width='100%'
      align='center'
      justify='center'
      filter='drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1))'
    >
      <Flex
        display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}
        flex='4'
        align='center'
        justify='space-between'
        h='80px'
        gap='16px'
        padding='0px 16px'
        bg='white'
        border='0.5px solid #8DC63F'
        borderRadius='8px 0px 0px 8px'
        width='100%'
      >
        <FloatingComponent
          open={isOpenOrigin}
          setOpen={() => toggleOpenOrigin(!isOpenOrigin)}
          placement={'bottom'}
          offset={20}
          css={{ width: '100%' }}
          floatingWidthBehaviour={'force'}
          component={
            <DropdownList
              cells={stopOptions.map(stop => ({
                label: stop.label,
                onClick: () => setOrigin(stop.value),
              }))}
              background={'white'}
              onClick={() => {}}
            />
          }
        >
          <Flex w='100%' gap='16px' as='button' onClick={() => toggleOpenOrigin(!isOpenOrigin)}>
            <Image src={pin} w='32px' />
            <Flex direction='column' gap='4px' align='flex-start'>
              <Text fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} fontWeight='700' color='#282828'>
                {t('home.from')}
              </Text>
              <Text fontSize={{ sm: '14px', md: '14px', lg: '12px', xl: '12px' }} fontWeight='600' color='#A3A3A3'>
                {origin || t('home.insert_starting_point')}
              </Text>
            </Flex>
          </Flex>
        </FloatingComponent>
        <Image src={swap} w='24px' />
        <FloatingComponent
          open={isOpenDestination}
          setOpen={() => toggleOpenDestination(!isOpenDestination)}
          placement={'bottom'}
          offset={20}
          css={{ width: '100%' }}
          floatingWidthBehaviour={'force'}
          component={
            <DropdownList
              cells={stopOptions.map(stop => ({
                label: stop.label,
                onClick: () => setDestination(stop.value),
              }))}
              background={'white'}
              onClick={() => {}}
            />
          }
        >
          <Flex
            w='100%'
            direction='column'
            gap='4px'
            align='flex-start'
            as='button'
            onClick={() => toggleOpenDestination(!isOpenDestination)}
          >
            <Text fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} fontWeight='700' color='#282828'>
              {t('home.to')}
            </Text>
            <Text fontSize={{ sm: '14px', md: '14px', lg: '12px', xl: '12px' }} fontWeight='600' color='#A3A3A3'>
              {destination || t('home.insert_destination')}
            </Text>
          </Flex>
        </FloatingComponent>
      </Flex>
      <Flex
        display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}
        direction='column'
        flex='1'
        align='flex-start'
        padding='8px 16px'
        bg='white'
        border='0.5px solid #8DC63F'
        borderRadius='4px'
        width='100%'
      >
        <FloatingComponent
          open={isOpenOrigin}
          setOpen={() => toggleOpenOrigin(!isOpenOrigin)}
          placement={'bottom'}
          offset={20}
          css={{ width: '100%' }}
          floatingWidth={360}
          floatingWidthBehaviour={'expand'}
          component={
            <DropdownList
              cells={stopOptions.map(stop => ({
                label: stop.label,
                onClick: () => setOrigin(stop.value),
              }))}
              background={'white'}
              onClick={() => {}}
            />
          }
        >
          <Flex
            gap='16px'
            h='64px'
            width='100%'
            align='center'
            as='button'
            onClick={() => toggleOpenOrigin(!isOpenOrigin)}
          >
            <Image src={pin} w='32px' />
            <Flex direction='column' gap='4px' align='flex-start'>
              <Text fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} fontWeight='700' color='#282828'>
                {t('home.from')}
              </Text>
              <Text fontSize={{ sm: '14px', md: '14px', lg: '12px', xl: '12px' }} fontWeight='600' color='#A3A3A3'>
                {origin || t('home.insert_starting_point')}
              </Text>
            </Flex>
          </Flex>
        </FloatingComponent>
        <Flex width='100%' justify='center'>
          <Flex padding='8px' bg='#F9F9FB' borderRadius='20px'>
            <Image transform='rotate(90deg)' src={swap} w='24px' />
          </Flex>
        </Flex>
        <FloatingComponent
          open={isOpenDestination}
          setOpen={() => toggleOpenDestination(!isOpenDestination)}
          placement={'bottom'}
          offset={20}
          css={{ width: '100%' }}
          floatingWidthBehaviour={'force'}
          component={
            <DropdownList
              cells={stopOptions.map(stop => ({
                label: stop.label,
                onClick: () => setDestination(stop.value),
              }))}
              background={'white'}
              onClick={() => {}}
            />
          }
        >
          <Flex
            gap='16px'
            h='64px'
            width='100%'
            align='center'
            as='button'
            onClick={() => toggleOpenDestination(!isOpenDestination)}
          >
            <Image src={pin} w='32px' />
            <Flex direction='column' gap='4px' align='flex-start'>
              <Text fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} fontWeight='700' color='#282828'>
                {t('home.to')}
              </Text>
              <Text fontSize={{ sm: '14px', md: '14px', lg: '12px', xl: '12px' }} fontWeight='600' color='#A3A3A3'>
                {destination || t('home.insert_destination')}
              </Text>
            </Flex>
          </Flex>
        </FloatingComponent>
      </Flex>

      <Flex
        flex={{ sm: '1', md: '1', lg: '3', xl: '3' }}
        h='80px'
        align='center'
        gap='16px'
        padding='8px 16px'
        bg='white'
        border='0.5px solid #8DC63F'
        borderRadius={{ sm: '4px', md: '4px', lg: '0', xl: '0' }}
        width='100%'
      >
        <FloatingComponent
          open={isOpenDate}
          setOpen={() => toggleOpenDate(!isOpenDate)}
          placement={'bottom'}
          offset={20}
          css={{ width: '100%' }}
          floatingWidthBehaviour={'force'}
          //component={
          //<Calendar minDate={} css={} onChange={} selectRange={true} focused={} onButtonPress={} maxDate={} value={date} />
          //}
        >
          <Flex w='100%' gap='16px' as='button' onClick={() => toggleOpenDate(!isOpenDate)}>
            <Flex direction='column' gap='4px' align='flex-start'>
              <Text fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} fontWeight='700' color='#282828'>
                {t('home.date')}
              </Text>
              <Text fontSize={{ sm: '14px', md: '14px', lg: '12px', xl: '12px' }} fontWeight='600' color='#A3A3A3'>
                {date || t('home.select_date')}
              </Text>
            </Flex>
          </Flex>
        </FloatingComponent>
      </Flex>

      <Flex
        flex={{ sm: '1', md: '1', lg: '3', xl: '3' }}
        h='80px'
        align='center'
        gap='16px'
        padding='8px 16px'
        bg='white'
        border='0.5px solid #8DC63F'
        borderRadius={{ sm: '4px', md: '4px', lg: '0', xl: '0' }}
        width='100%'
      >
        <FloatingComponent
          open={isOpenPassengers}
          setOpen={() => toggleOpenPassengers(!isOpenPassengers)}
          placement={'bottom'}
          offset={20}
          css={{ width: '100%', alignItems: 'flex-start' }}
          floatingWidthBehaviour={'force'}
          component={
            <DropdownList
              cells={numberPassengers.map(number => ({
                label: number.label,
                onClick: () => setPassengers(number.value),
              }))}
              background={'white'}
              onClick={() => {}}
            />
          }
        >
          <Flex w='100%' gap='16px' as='button' onClick={() => toggleOpenPassengers(!isOpenPassengers)}>
            <Image src={user} w='32px' />
            <Flex direction='column' gap='4px' align='flex-start'>
              <Text fontSize={{ sm: '13px', md: '13px', lg: '14px', xl: '14px' }} fontWeight='700' color='#282828'>
                {t('home.number_passengers')}
              </Text>
              <Text fontSize={{ sm: '14px', md: '14px', lg: '12px', xl: '12px' }} fontWeight='600' color='#A3A3A3'>
                {passengers || t('home.insert_passengers')}
              </Text>
            </Flex>
          </Flex>
        </FloatingComponent>
      </Flex>
      <Flex display={{ sm: 'none', lg: 'flex' }}>
        <Button
          onClick={() => handleNavigate()}
          disabled={!origin || !destination || !passengers}
          label={t('home.search')}
          style='solid'
          type='neutralDark'
          css={{ height: '80px', borderRadius: '0px 8px 8px 0px' }}
        />
      </Flex>
      <Flex display={{ sm: 'flex', lg: 'none' }}>
        <Button
          disabled={!origin || !destination || !date || !passengers}
          label={t('home.search')}
          style='solid'
          type='neutralDark'
          css={{ height: '80px', borderRadius: '4px' }}
        />
      </Flex>
    </Flex>
  )
}
