import * as EmailValidator from 'email-validator'
import i18n from '../i18n'

export const hasErrors = errors => {
  return Object.keys(errors).length > 0
}

export const requiredValidation = () => {
  return i18n.t('errors:requiredField')
}

export const requiredAllowZeroValidation = value => {
  return (value !== undefined && value !== null) || i18n.t('errors:requiredField')
}

export const emailValidation = email => {
  if (!email) return true
  return EmailValidator.validate(email) || i18n.t('authentication.invalid_email')
}

export const nifValidation = nif => {
  if (
    !['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
    !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2))
  )
    return false

  let total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2
  let module11 = total - parseInt(total / 11) * 11
  let comparator = module11 == 1 || module11 == 0 ? 0 : 11 - module11

  return nif[8] == comparator || i18n.t('authentication.invalid_tax_id')
}

export const passwordMatchValidation = (value, matchingValue) => {
  return value === matchingValue || i18n.t('errors:passwordMatch')
}

export const minLengthValidation = (value, length, field) => {
  return value.length >= length || i18n.t('errors:minLength', { field, length })
}

export const maxLengthValidation = (value, length, field) => {
  return value.length <= length || i18n.t('errors:maxLength', { field, length })
}

export const requiredArrayValidation = affected => {
  return affected.length > 0 || i18n.t('errors:requiredField')
}
