import { Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import config from '../../../../config/configLoader'

import bgDesktop from '../../../../assets/agents_bg_desktop.png'
import bgMobile from '../../../../assets/agents_bg_mobile.png'

export const TicketAgents = ({ agents }) => {
  const { t } = useTranslation()

  const numAgents = config.sections.ticket_agents
  const numCols = numAgents >= 4 ? 2 : 1

  return (
    <>
      <Image display={{ sm: 'flex', lg: 'none' }} src={bgMobile} />

      <div id='agents'>
        <Flex
          ref={agents}
          direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
          bg='white'
          w='100%'
          justify='space-between'
          align='center'
          position='relative'
          overflow='hidden'
          gap='88px'
        >
          <Flex
            direction='column'
            gap='48px'
            w='100%'
            padding={{ sm: '48px 24px', md: '48px 24px', lg: '24px 0 24px 70px', xl: '36px 0 36px 130px' }}
          >
            <Flex direction='column'>
              <Text fontSize={{ sm: '28px', md: '28px', lg: '36px', xl: '36px' }} fontWeight='700' color='#1D2939'>
                {t('ticket_fares.operator_agents_title')}
              </Text>
              <Text fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }} fontWeight='600' color='#1D2939'>
                {t('ticket_fares.operator_agents_subtitle')}
              </Text>
            </Flex>

            <Flex
              direction='column'
              gap={{ sm: '48px', md: '48px', lg: '32px', xl: '32px' }}
              justify='flex-start'
              align='flex-start'
            >
              <Flex
                direction='column'
                display={{sm: "flex", md: 'grid'}}
                gridTemplateColumns={`repeat(${numCols}, 1fr)`}
                columnGap='80px'
                rowGap='24px'
              >
                {Array.from({ length: numAgents }, (_, index) => (
                  <Flex key={index} direction='column' gap='8px'>
                    <Text
                      fontSize={{ sm: '16px', md: '16px', lg: '18px', xl: '18px' }}
                      fontWeight='700'
                      color='#1D2939'
                    >
                      {t(`ticket_fares.sale_agent_${index + 1}`)}
                    </Text>
                    <Flex direction='column'>
                      {t(`ticket_fares.address_sale_agent_${index + 1}`)
                        .split('\\n')
                        .map((address, addrIndex) => (
                          <Text key={addrIndex} fontSize='14px' color='#1D2939'>
                            {address}
                          </Text>
                        ))}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>

          <Image
            src={bgDesktop}
            display={{ sm: 'none', lg: 'flex' }}
            w={{ lg: '50%' }}
            maxH={{ lg: '652px', xl: '652px' }}
          />
        </Flex>
      </div>
    </>
  )
}

export default TicketAgents
