import { Flex, Text, Image } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import check from '../../../../../../assets/green_checkmark.svg'
import cross from '../../../../../../assets/grey_cross.svg'

export function TagDay({ day, isTrue }) {
  const { t } = useTranslation()

  const weekdayAbbreviations = {
    monday: t('network_schedule.monday'),
    tuesday: t('network_schedule.tuesday'),
    wednesday: t('network_schedule.wednesday'),
    thursday: t('network_schedule.thursday'),
    friday: t('network_schedule.friday'),
    saturday: t('network_schedule.saturday'),
    sunday: t('network_schedule.sunday'),
  }

  return (
    <Flex h='36px' p={{ sm: '4px', lg: '8px' }} gap='4px' justify='center' align='center'>
      <Image src={isTrue ? check : cross} />
      <Text fontSize={{ sm: '12px', lg: '13px' }} fontWeight='600' color={isTrue ? '#01AE70' : '#98A2B3'}>
        {weekdayAbbreviations[day]}
      </Text>
    </Flex>
  )
}
