import { Flex, Progress, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { RouteLabel } from '@ubirider/pick-component-library'
import { useNavigate } from 'react-router-dom'

function SearchResultsCard({
  network,
  filteredRoutes,
  filteredStops,
  updateSelectedStop,
  handleHoverRoute,
  handleHoverStop,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [allRoutes, setAllRoutes] = useState(false)
  const [allStops, setAllStops] = useState(false)

  function handleClickRoute(routeID) {
    setLoading(true)
    navigate(`/network-and-schedules/route/${routeID}/0`)
  }

  function handleClickStop(stop) {
    setLoading(true)
    updateSelectedStop(JSON.parse(stop.routeIDs)[0], stop.id, stop.name)
    navigate(`/network-and-schedules/stop/${stop.id}`)
    handleHoverRoute(JSON.parse(stop.routeIDs)[0])
    handleHoverStop(stop.id)
  }

  return (
    <Flex
      direction='column'
      border='1px solid #D0D5DD'
      borderRadius='8px'
      bg='white'
      boxShadow='0px 2px 20px 1px rgba(0, 0, 0, 0.10)'
    >
      {loading && <Progress size='xs' isIndeterminate borderRadius='64px 64px 0 0' style={{ color: network.color }} />}
      <Flex direction='column' padding='8px' mt={loading ? '-4px' : ''} maxH='500px' overflowY='auto'>
        {filteredRoutes.length > 0 && !allStops && (
          <Flex h='48px' padding='16px' align='center' justify='space-between'>
            <Text fontSize='16px' fontWeight='700' color='#98A2B3'>
              {t('network_schedule.routes')}
            </Text>
            <Flex as='button' onClick={() => setAllRoutes(!allRoutes)}>
              {allRoutes && filteredRoutes.length > 3 && (
                <Text fontSize='14px' fontWeight='600' color='#667085'>
                  {t('common.view_less')}
                </Text>
              )}
              {!allRoutes && filteredRoutes.length > 3 && (
                <Text fontSize='14px' fontWeight='600' color='#667085'>
                  {t('network_schedule.see_all_routes')}
                </Text>
              )}
            </Flex>
          </Flex>
        )}
        {allRoutes &&
          !allStops &&
          filteredRoutes?.map((route, index) => (
            <Flex
              key={index}
              h='48px'
              padding='16px'
              align='center'
              gap='8px'
              rounded='4px'
              as='button'
              onClick={() => handleClickRoute(route.id)}
              onMouseEnter={() => handleHoverRoute(route.id)}
              onMouseLeave={() => handleHoverRoute('')}
              _hover={{ bg: '#F2F4F7' }}
            >
              <RouteLabel shortName={route.shortName} color={route.color} textColor={route.textColor} />
              <Text textAlign='left' fontSize='14px' fontWeight='600' color='#475467'>
                {route?.name.replace(
                  /([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g,
                  match => match.charAt(0) + match.slice(1).toLowerCase()
                )}
              </Text>
            </Flex>
          ))}
        {!allRoutes &&
          !allStops &&
          filteredRoutes?.slice(0, 3)?.map((route, index) => (
            <Flex
              key={index}
              h='48px'
              padding='16px'
              align='center'
              gap='8px'
              rounded='4px'
              as='button'
              onClick={() => handleClickRoute(route.id)}
              onMouseEnter={() => handleHoverRoute(route.id)}
              onMouseLeave={() => handleHoverRoute('')}
              _hover={{ bg: '#F2F4F7' }}
            >
              <RouteLabel shortName={route.shortName} color={route.color} textColor={route.textColor} />
              <Text textAlign='left' fontSize='14px' fontWeight='600' color='#475467'>
                {route?.name.replace(
                  /([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g,
                  match => match.charAt(0) + match.slice(1).toLowerCase()
                )}
              </Text>
            </Flex>
          ))}
        {filteredStops.length > 0 && !allRoutes && (
          <Flex h='48px' padding='16px' align='center' justify='space-between'>
            <Text fontSize='16px' fontWeight='700' color='#98A2B3'>
              {t('network_schedule.stops')}
            </Text>
            <Flex as='button' onClick={() => setAllStops(!allStops)}>
              {allStops && filteredStops.length > 3 && (
                <Text fontSize='14px' fontWeight='600' color='#667085'>
                  {t('common.view_less')}
                </Text>
              )}
              {!allStops && filteredStops.length > 3 && (
                <Text fontSize='14px' fontWeight='600' color='#667085'>
                  {t('network_schedule.see_all_stops')}
                </Text>
              )}
            </Flex>
          </Flex>
        )}
        {allStops &&
          !allRoutes &&
          filteredStops?.map((stop, index) => (
            <Flex
              key={index}
              h='48px'
              padding='16px'
              align='center'
              justify='space-between'
              rounded='4px'
              as='button'
              onClick={() => handleClickStop(stop)}
              onMouseEnter={() => handleHoverStop(stop.id)}
              onMouseLeave={() => handleHoverStop('')}
              _hover={{ bg: '#F2F4F7' }}
            >
              <Text textAlign='left' fontSize='14px' fontWeight='600' color='#475467'>
                {stop?.name.replace(
                  /([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g,
                  match => match.charAt(0) + match.slice(1).toLowerCase()
                )}
              </Text>
            </Flex>
          ))}
        {!allStops &&
          !allRoutes &&
          filteredStops?.slice(0, 3)?.map((stop, index) => (
            <Flex
              key={index}
              h='48px'
              padding='16px'
              align='center'
              justify='space-between'
              rounded='4px'
              as='button'
              onClick={() => handleClickStop(stop)}
              onMouseEnter={() => handleHoverStop(stop.id)}
              onMouseLeave={() => handleHoverStop('')}
              _hover={{ bg: '#F2F4F7' }}
            >
              <Text textAlign='left' fontSize='14px' fontWeight='600' color='#475467'>
                {stop?.name.replace(
                  /([A-ZÁÉÍÓÚÃÕÂÊÔÇÀÜÈÌÒÙ]+)/g,
                  match => match.charAt(0) + match.slice(1).toLowerCase()
                )}
              </Text>
            </Flex>
          ))}
      </Flex>
    </Flex>
  )
}

export default SearchResultsCard
