import { Divider, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { TransportIcon } from '@ubirider/pick-component-library'
import React, { useState } from 'react'

import dotIcon from '../../../../../../assets/dot_full.svg'
import dotSelectedIcon from '../../../../../../assets/dot_full_selected.svg'

function RouteStopsList({
  selectedNetwork,
  selectedPattern,
  stopSequence,
  hoveredStop,
  handleHoverStop,
  handleClickStop,
}) {
  const { t } = useTranslation()

  const [viewRoutes, setView] = useState(true)

  const realTimeStopIDs = selectedNetwork?.real_time?.map(obj => obj.stops[0].stopSequence) || []

  return (
    <Flex direction='column' gap='8px'>
      <Flex justify='space-between'>
        <Text
          as='button'
          onClick={() => setView(!viewRoutes)}
          fontSize='13px'
          fontWeight='700'
          color='#424D5F'
          textDecoration='underline'
        >
          {viewRoutes ? t('network_schedule.hide_stops') : t('network_schedule.view_stops')}
        </Text>
        {viewRoutes &&
          stopSequence?.every(stop => stop && stop?.hasOwnProperty('zoneName') && stop?.zoneName !== null) && (
            <Text fontSize='13px' fontWeight='700' color='#424D5F'>
              {t('network_schedule.zones')}
            </Text>
          )}
      </Flex>
      <Flex direction='column'>
        {viewRoutes &&
          stopSequence?.map((stop, i) => (
            <>
              <Flex
                padding='10px 8px 10px 0'
                gap='8px'
                rounded='4px'
                cursor='pointer'
                align='center'
                bg={hoveredStop === stop?.id ? '#F2F4F7' : 'white'}
                onMouseEnter={() => handleHoverStop(stop?.id)}
                onMouseLeave={() => handleHoverStop('')}
                as='button'
                onClick={() => handleClickStop(stop)}
                position='relative'
              >
                <div
                  className={
                    i === 0 ? 'stop-line-first' : i === stopSequence.length - 1 ? 'stop-line-last' : 'stop-line'
                  }
                />
                <Image
                  zIndex='5'
                  src={
                    realTimeStopIDs.includes(i + 1) &&
                    selectedNetwork?.real_time.some(
                      realTime => realTime.patternID === selectedNetwork.route.patterns.ids[selectedPattern]
                    )
                      ? dotSelectedIcon
                      : dotIcon
                  }
                />
                <Flex align='center' justify='space-between' gap='8px' w='100%'>
                  <Text
                    maxW={stop?.zoneName ? '65%' : '85%'}
                    fontSize='14px'
                    fontWeight={
                      realTimeStopIDs.includes(i + 1) &&
                      selectedNetwork?.real_time.some(
                        realTime => realTime.patternID === selectedNetwork.route.patterns.ids[selectedPattern]
                      )
                        ? '700'
                        : '400'
                    }
                    color='#475467'
                    textDecoration={{ sm: 'underline', md: 'none' }}
                    overflow='hidden'
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                  >
                    {stop?.name}
                  </Text>
                  <Flex gap='24px' justify='end' align='center'>
                    {realTimeStopIDs.includes(i + 1) &&
                      selectedNetwork?.real_time.some(
                        realTime => realTime.patternID === selectedNetwork.route.patterns.ids[selectedPattern]
                      ) && (
                        <Flex padding='4px' borderRadius='20px' boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.20)'>
                          <TransportIcon size={'small'} type={3} />
                        </Flex>
                      )}
                    {stopSequence?.every(
                      stop => stop && stop?.hasOwnProperty('zoneName') && stop?.zoneName !== null
                    ) && (
                      <Text fontSize='14px' color='#475467'>
                        {stop.zoneName}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Flex>
              {i !== stopSequence.length - 1 && (
                <Divider width='auto' maxW='500px' ml='3px' mr='3px' border='1px solid #D0D5DD' />
              )}
            </>
          ))}
      </Flex>
    </Flex>
  )
}

export default RouteStopsList
