import config from '../../config/configLoader'
import { TrevoPrivacyPolicy } from './components/TrevoPrivacyPolicy'
import { ProximoPrivacyPolicy } from './components/ProximoPrivacyPolicy'
import { BejaPrivacyPolicy } from './components/BejaPrivacyPolicy'
import { MobiAzoresPrivacyPolicy } from './components/MobiAzoresPrivacyPolicy'

function PrivacyPolicy() {
  if (config.operator === 'trevo') {
    return (<TrevoPrivacyPolicy  />)
  }

  if (config.operator === 'proximo') {
    return (<ProximoPrivacyPolicy />)
  }

  if (config.operator === 'BEJA') {
    return (<BejaPrivacyPolicy />)
  }

  if (config.operator === 'mobiazores') {
    return (<MobiAzoresPrivacyPolicy />)
  }
}

export default PrivacyPolicy
