import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, RouteLabel } from '@ubirider/pick-component-library'

import warningCircleIcon from '../../../../assets/warning_circle.svg'
import { ScheduleNumbers } from '../../../../consts/schedules'
import config from '../../../../config/configLoader'
import downloadIcon from '../../../../assets/download_white.svg'

export function RouteScheduleTable({ schedules, allNetwork, currentWeekday, selectedRoute, isException }) {
  const { t } = useTranslation()

  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']

  console.log(schedules, currentWeekday)

  if (schedules.every(obj => obj?.schedule?.length === 0 || obj?.schedule === undefined)) {
    return (
      <Flex
        padding={{ sm: '36px' }}
        direction='column'
        gap='8px'
        align='center'
        justify='center'
        h={{ sm: '100%', lg: '400px' }}
        w='100%'
      >
        <Image src={warningCircleIcon} />
        <Text fontSize='20px' fontWeight='600' color='#98A2B3' textAlign='center'>
          {weekdays.includes(currentWeekday) && !isException
            ? t('network_schedule.schedules_routes_empty_state_weekdays')
            : currentWeekday === 'saturday' && !isException
              ? t('network_schedule.schedules_routes_empty_state_saturdays')
              : currentWeekday === 'sunday' && !isException
                ? t('network_schedule.schedules_routes_empty_state_holidays')
                : t('network_schedule.schedules_routes_empty_state_selected_date')}
        </Text>
      </Flex>
    )
  } else {
    return (
      <>
        <Flex
          display={{ sm: 'none', lg: 'flex' }}
          padding={{ sm: '16px', lg: '0px 24px 16px 24px' }}
          gap='8px'
          h='100%'
        >
          <Flex direction='column' align='flex-start'>
            {schedules?.map((entity, index) => (
              <Flex key={index} h='42px' w='260px' padding='10px 0 10px 16px' align='center' borderRadius='8px 0 0 8px'>
                <Tooltip
                  hasArrow
                  bg='#475467'
                  rounded='4px'
                  placement='top-start'
                  label={entity?.stop?.name}
                  aria-label={entity?.stop?.name}
                >
                  <Text
                    textAlign='left'
                    fontSize='13px'
                    fontWeight='600'
                    color='#1D2939'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                  >
                    {entity?.stop?.name}
                  </Text>
                </Tooltip>
              </Flex>
            ))}
          </Flex>
          <Flex
            bg='linear-gradient(90deg, rgba(217, 217, 217, 0.50) 18.75%, rgba(217, 217, 217, 0.00) 100%)'
            h={'calc(' + schedules.length + '* 42px)'}
            w='8px'
          ></Flex>
          <Flex direction='column' align='flex-start' w={{ lg: '480px', xl: '700px' }} overflowX='auto'>
            {schedules?.map((entity, index) => (
              <Flex h='42px' key={index} padding='10px 0' align='center' justify='space-between' gap='16px'>
                {entity.schedule?.map(time => (
                  <Text textAlign='left' fontSize='13px' fontWeight='600' color='#1D2939'>
                    {time?.replace(/:\d{2}$/, '')}
                  </Text>
                ))}
              </Flex>
            ))}
          </Flex>
          <Flex
            bg='linear-gradient(90deg, rgba(217, 217, 217, 0.00) 12.75%, rgba(217, 217, 217, 0.65) 100%)'
            h={'calc(' + schedules.length + '* 42px)'}
            w='8px'
          ></Flex>
          <Flex direction='column' align='flex-start'>
            {schedules?.map((entity, index) => (
              <Flex w='160px' h='42px' key={index} padding='10px 0' align='center' borderRadius='0 8px 8px 0'>
                <Tooltip
                  hasArrow
                  bg='white'
                  border='1px solid #D0D5DD'
                  boxShadow='0px 2px 15px rgba(117, 55, 250, 0.20)'
                  rounded='4px'
                  maxW='calc(100vw)'
                  padding='16px'
                  placement='left'
                  label={
                    <Flex direction='column' gap='8px' align='flex-start'>
                      {JSON.parse(entity?.stop?.routeIDs).map(id => {
                        if (allNetwork.routes.find(route => route.id === id))
                          return (
                            <Flex align='center' maxW='calc(100vw)'>
                              <RouteLabel
                                shortName={allNetwork.routes.find(route => route.id === id).shortName}
                                color={allNetwork.routes.find(route => route.id === id).color}
                                textColor={allNetwork.routes.find(route => route.id === id).textColor}
                              />
                              <Flex
                                maxW='calc(100vw)'
                                padding='1px 8px 1.5px 12px'
                                ml='-4px'
                                borderTop='1px solid rgba(71, 84, 103, 0.40)'
                                borderRight='1px solid rgba(71, 84, 103, 0.40)'
                                borderBottom='1px solid rgba(71, 84, 103, 0.40)'
                                rounded='0 4px 4px 0'
                                align='center'
                              >
                                <Text fontSize='13px' fontWeight='600' color='#1D2939'>
                                  {allNetwork.routes
                                    .find(route => route.id === id)
                                    .name.toLowerCase()
                                    .replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
                                </Text>
                              </Flex>
                            </Flex>
                          )
                        return null
                      })}
                    </Flex>
                  }
                >
                  <Flex gap='8px' opacity='1' w='160px' align='center'>
                    {JSON.parse(entity?.stop?.routeIDs)
                      .slice(0, 2)
                      .map(id => {
                        if (allNetwork.routes.find(route => route.id === id))
                          return (
                            <RouteLabel
                              shortName={allNetwork.routes.find(route => route.id === id).shortName}
                              color={allNetwork.routes.find(route => route.id === id).color}
                              textColor={allNetwork.routes.find(route => route.id === id).textColor}
                            />
                          )
                        return null
                      })}
                    {JSON.parse(entity?.stop?.routeIDs).length > 2 && (
                      <Text fontSize='13px' fontWeight='600' color='#1D2939'>
                        +{JSON.parse(entity?.stop?.routeIDs).length - 2}
                      </Text>
                    )}
                  </Flex>
                </Tooltip>
              </Flex>
            ))}
          </Flex>
        </Flex>

        <Flex
          direction='column'
          mt='16px'
          gap='16px'
          display={{ sm: 'flex', lg: 'none' }}
          padding={{ sm: '16px', lg: '16px 24px' }}
        >
          <Text fontSize='14px' fontWeight='700' color='#424D5F' textAlign='left'>
            {t('network_schedule.stops')}
          </Text>
          <Accordion w='100%' allowToggle>
            {schedules?.map((entity, index) => (
              <AccordionItem key={index} rounded='4px' w='100%' border='none'>
                {({ isExpanded }) => (
                  <Flex direction='column' w='100%' rounded='4px'>
                    <AccordionButton padding='8px' w='100%' bg={isExpanded ? '#F2F4F7' : 'white'}>
                      <Flex justifyContent='space-between' align='center' w='100%'>
                        <Text
                          fontSize='14px'
                          fontWeight={isExpanded ? '700' : '400'}
                          color='#1D2939'
                          textAlign='left'
                          overflow='hidden'
                          textOverflow='ellipsis'
                          whiteSpace='nowrap'
                        >
                          {entity?.stop?.name}
                        </Text>
                        <AccordionIcon color='#344054' />
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel rounded='8px' bg={isExpanded ? '#F2F4F7' : 'white'}>
                      <Flex direction='column' gap='16px'>
                        <Flex direction='column' flex='1' gap='8px'>
                          {entity.schedule?.map(time => (
                            <Text textAlign='left' fontSize='13px' color='#1D2939'>
                              {time?.replace(/:\d{2}$/, '')}
                            </Text>
                          ))}
                        </Flex>
                        <Flex direction='column' gap='8px' align='flex-start'>
                          <Text textAlign='left' fontSize='13px' fontWeight='600' color='#424D5F'>
                            {t('network_schedule.connections_in_stop')}
                          </Text>
                          <Flex gap='16px' overflowX='auto' maxW='100%' pb='16px' mb='-16px'>
                            {JSON.parse(entity?.stop?.routeIDs).map(id => {
                              if (allNetwork.routes.find(route => route.id === id))
                                return (
                                  <RouteLabel
                                    shortName={allNetwork.routes.find(route => route.id === id).shortName}
                                    color={allNetwork.routes.find(route => route.id === id).color}
                                    textColor={allNetwork.routes.find(route => route.id === id).textColor}
                                  />
                                )
                              return null
                            })}
                          </Flex>
                        </Flex>
                      </Flex>
                    </AccordionPanel>
                  </Flex>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Flex>

        <Flex
          display={{ sm: 'flex', lg: 'none' }}
          bottom='0'
          padding='0 8px 8px 8px'
          bg='white'
          width='100%'
          justifyContent='center'
          alignItems='center'
        >
          {config.sections.schedules.download && (
            <a
              href={ScheduleNumbers[config.operator][selectedRoute]?.link}
              download={ScheduleNumbers[config.operator][selectedRoute]?.name + '.pdf'}
              target='_blank'
              width='100%'
              rel='noreferrer'
            >
              <Button
                disabled={ScheduleNumbers[config.operator][selectedRoute]?.link === ''}
                style='solid'
                size='small'
                type='neutralDark'
                label={t('common.download')}
                rightIconSrc={{ src: downloadIcon }}
                css={{ width: '100%' }}
              />
            </a>
          )}
        </Flex>
      </>
    )
  }
}
