import { Flex, Text, Image, Divider, Link } from '@chakra-ui/react'
import config from '../../../config/configLoader'

import redirect from '../../../assets/redirect.svg'
import { useTranslation } from 'react-i18next'

export function NewsCard(props) {
  const { t } = useTranslation()

  return (
    <Link
      href={props.url}
      className='chakra-custom-link'
      target='_blank'
      rel='noopener noreferrer'
      _hover={{ textDecoration: 'none' }}
    >
      <Flex
        direction='column'
        align='flex-start'
        justify='space-between'
        gap='16px'
        h={{ sm: '416px', md: '416px', lg: '536px', xl: '536px', xxl: '586px' }}
        w={{ sm: '280px', md: '280px', lg: '380px', xl: '380px', xxl: '480px' }}
        border='1px solid #98A2B3'
        bg='white'
        boxShadow='0px 2px 20px 1px rgba(0, 0, 0, 0.1)'
        borderRadius='16px'
        padding='16px'
      >
        <Flex direction='column' gap='16px'>
          <Flex w='100%' h={{ sm: '172px', lg: '212px' }} overflow='hidden' borderRadius='10px'>
            <Image src={props.image} w='100%' h='100%' objectFit='cover' />
          </Flex>
          <Text fontSize='16px' fontWeight='700' color='#1D2939'>
            {props.title}
          </Text>
          <Text fontSize='14px' color='#1D2939' display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}>
            {props.description}
          </Text>
        </Flex>
        <Flex direction='column' gap='16px' align='flex-start' justify='flex-end' width='100%'>
          <Divider border='1px solid #98A2B3' />
          <Text fontSize='14px' color='#475467'>
            {t('home.x_min_read', { minutes: props.duration })}
          </Text>
          <Flex gap='16px' align='center' justify='space-between' width='100%'>
            <Text fontSize='13px' fontWeight='600' color='#475467'>
              {props.source}
            </Text>
            <Image src={redirect} filter={`brightness(0) saturate(100%) hue-rotate(${config.mainColor})`} />
          </Flex>
        </Flex>
      </Flex>
    </Link>
  )
}
