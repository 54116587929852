import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { RealtimeIcon } from '@ubirider/pick-component-library'
import React from 'react'

function RouteRealTimeInfo({ selectedNetwork, selectedPattern }) {
  const { t } = useTranslation()

  function convertToLocaleTimeString(timestamp) {
    const date = new Date(timestamp * 1000)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }

  return (
    <Flex direction='column' gap='8px'>
      <Text fontSize='14px' fontWeight='600' color='#1D2939'>
        {t('network_schedule.real_time_information')}
      </Text>
      {selectedNetwork?.real_time?.length > 0 &&
        selectedNetwork?.real_time?.some(
          bus => bus.patternID === selectedNetwork.route.patterns.ids[selectedPattern]
        ) && (
          <Flex gap='16px' justify='space-between' align='center'>
            <Flex direction='column' gap='8px'>
              <Tooltip
                hasArrow
                bg='#475467'
                rounded='4px'
                placement='bottom-start'
                label={t('network_schedule.scheduled_time_of_arrival')}
                aria-label={t('network_schedule.scheduled_time_of_arrival')}
              >
                <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                  STA
                </Text>
              </Tooltip>
              {selectedNetwork.real_time.map(bus => {
                if (bus.patternID === selectedNetwork.route.patterns.ids[selectedPattern]) {
                  return (
                    <Text fontSize='14px' color='#475467'>
                      {convertToLocaleTimeString(bus.stops[0].scheduledTimestamp)}
                    </Text>
                  )
                }
              })}
            </Flex>
            <Flex direction='column' gap='8px'>
              <Tooltip
                hasArrow
                bg='#475467'
                rounded='4px'
                placement='bottom-start'
                label={t('network_schedule.estimated_time_of_arrival')}
                aria-label={t('network_schedule.estimated_time_of_arrival')}
              >
                <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                  ETA
                </Text>
              </Tooltip>
              {selectedNetwork.real_time.map(bus => {
                if (bus.patternID === selectedNetwork.route.patterns.ids[selectedPattern]) {
                  return (
                    <Flex gap='2px' align='center'>
                      <RealtimeIcon
                        size={'verySmall'}
                        fill={
                          bus.stops[0].diff < -60 || bus.stops[0].diff > 600
                            ? '#D23636'
                            : bus.stops[0].diff > 180
                              ? '#C6901E'
                              : '#067F5B'
                        }
                      />
                      <Text
                        fontSize='14px'
                        color={
                          bus.stops[0].diff < -60 || bus.stops[0].diff > 600
                            ? '#D23636'
                            : bus.stops[0].diff > 180
                              ? '#C6901E'
                              : '#067F5B'
                        }
                      >
                        {convertToLocaleTimeString(bus.stops[0].realtimeTimestamp)}
                      </Text>
                    </Flex>
                  )
                }
              })}
            </Flex>
            <Flex direction='column' gap='8px' align='flex-end'>
              <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                {t('network_schedule.status')}
              </Text>
              {selectedNetwork?.real_time?.map(bus => {
                if (bus.patternID === selectedNetwork.route.patterns.ids[selectedPattern]) {
                  return (
                    <Text
                      fontSize='13px'
                      fontWeight='600'
                      color={
                        bus.stops[0].diff < -60 || bus.stops[0].diff > 600
                          ? '#D23636'
                          : bus.stops[0].diff > 180
                            ? '#C6901E'
                            : '#067F5B'
                      }
                    >
                      {bus.stops[0].diff < -60
                        ? t('network_schedule.early')
                        : bus.stops[0].diff > 180
                          ? t('network_schedule.delayed')
                          : bus.stops[0].diff > 180
                            ? t('network_schedule.delayed')
                            : t('network_schedule.on_time')}
                    </Text>
                  )
                }
              })}
            </Flex>
          </Flex>
        )}
      {(selectedNetwork?.real_time?.length === 0 ||
        !selectedNetwork?.real_time?.some(
          bus => bus.patternID === selectedNetwork.route.patterns.ids[selectedPattern]
        )) && (
        <Flex padding='16px' bg='#E0EAFF' border='1px solid #C7D7FE' borderRadius='4px'>
          <Text fontSize='14px' color='#444CE7'>
            {t('network_schedule.issues_unable_to_display_realtime_map')}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export default RouteRealTimeInfo
