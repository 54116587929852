import React, { useEffect } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { Flex, useDisclosure } from '@chakra-ui/react'
import i18next from 'i18next'
import config from './config/configLoader'
import { Home } from './pages/Home'
import { Header } from './pages/Header'
import { Footer } from './pages/Footer'
import { TicketFares } from './pages/TicketFares'
import { NetworkSchedules } from './pages/NetworkSchedules'
import { News } from './pages/News'
import { Support } from './pages/Support'
import { SearchResults } from './pages/SearchResults'
import { Recruitment } from './pages/Recruitment'
import { Faqs } from './pages/Faqs'
import { TermsConditions } from './pages/TermsConditions'
import { PrivacyPolicy } from './pages/PrivacyPolicy'
import { About } from './pages/About'
import { Promotion } from './pages/Promotion'
import { setLanguage } from '@ubirider/pick-component-library'
import { PaymentDetails } from './pages/PaymentDetails'
import { PassengerRights } from './pages/PassengerRights'
import { PassMigration } from './pages/PassMigration'
import { NotificationCenter } from './pages/NotificationCenter'
import { CookiePermissionModal } from './modals/CookiePermissionModal'
import { PassRequestPolicy } from './pages/PassRequestPolicy'
import { CookiePolicy } from './pages/CookiePolicy'
import Cookies from 'universal-cookie/es6'

function App() {
  const cookies = new Cookies()
  const cookiePerms = cookies.get('CookieConsent')
  const language = localStorage.getItem('Language')
  const userLang =
    navigator.language.substring(0, navigator.language.indexOf('-')).toUpperCase() === 'PT'
      ? navigator.language.substring(0, navigator.language.indexOf('-')).toUpperCase()
      : 'EN'
  if (language) {
    i18next.changeLanguage(language)
    setLanguage(language.toLowerCase())
  } else {
    localStorage.setItem('Language', userLang)
    i18next.changeLanguage(userLang)
    setLanguage(userLang.toLowerCase())
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (!cookiePerms) onOpen()
  }, [])

  return (
    <Router>
      <Flex direction='column' flex='1' minH='100vh'>
        <Header />
        <Flex gap='20px' direction='column' pt='70px' flex='1'>
          <Routes>
            <Route path='/' element={<Home />} />

            <Route path='/ticket-fares' element={<TicketFares />} />
            <Route path='/ticket-fares/pass-request/pass-info' element={<TicketFares />} />
            <Route path='/ticket-fares/pass-request/personal-info' element={<TicketFares />} />
            <Route path='/ticket-fares/pass-request/additional-info' element={<TicketFares />} />
            <Route path='/ticket-fares/pass-request/summary' element={<TicketFares />} />
            <Route path='/ticket-fares/pass-request/checkout' element={<TicketFares />} />
            <Route path='/ticket-fares/fail' element={<TicketFares />} />
            <Route path='/ticket-fares/success' element={<TicketFares />} />

            <Route path='/network-and-schedules' element={<NetworkSchedules />} />
            <Route path='/network-and-schedules/route/:r/:p' element={<NetworkSchedules />} />
            <Route path='/network-and-schedules/stop/:s' element={<NetworkSchedules />} />

            <Route path='/search/:o/:d/:t/:p' element={<SearchResults />} />
            <Route path='/payment' element={<PaymentDetails />} />

            <Route path='/notification-center' element={<NotificationCenter />} />
            <Route path='/notification-center/critical-alerts' element={<NotificationCenter />} />
            <Route path='/notification-center/warning-alerts' element={<NotificationCenter />} />
            <Route path='/notification-center/informative-alerts' element={<NotificationCenter />} />

            {config.sections.news && <Route path='/news' element={<News />} />}
            {config.sections.support && <Route path='/support' element={<Support />} />}
            {config.sections.recruitment && <Route path='/recruitment' element={<Recruitment />} />}
            {config.sections.faqs && <Route path='/faqs' element={<Faqs />} />}
            {config.sections.terms_conditions && <Route path='/terms-and-conditions' element={<TermsConditions />} />}
            {config.sections.privacy_policy && <Route path='/privacy-policy' element={<PrivacyPolicy />} />}
            {config.sections.passenger_rights && <Route path='/passenger-rights' element={<PassengerRights />} />}
            {config.sections.pass_request_policy && (
              <Route path='/pass-request-policy' element={<PassRequestPolicy />} />
            )}
            {config.sections.cookie_policy && (
              <Route path='/cookie-policy' element={<CookiePolicy openCookiePopup={onOpen} />} />
            )}
            {config.sections.about && <Route path='/about' element={<About />} />}
            {config.sections.promotion && <Route path='/promotion' element={<Promotion />} />}

            {config.sections.migration && (
              <>
                <Route path='/pass-migration' element={<PassMigration />} />
                <Route path='/pass-migration/:name/:number' element={<PassMigration />} />
                <Route path='/pass-migration/success' element={<PassMigration />} />
                <Route path='/pass-migration/pass-request/personal-info' element={<PassMigration />} />
                <Route path='/pass-migration/pass-request/pass-info' element={<PassMigration />} />
                <Route path='/pass-migration/pass-request/checkout' element={<PassMigration />} />
              </>
            )}

            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </Flex>

        <Footer />
      </Flex>

      <CookiePermissionModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Router>
  )
}

export default App
