import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { RealtimeIcon } from '@ubirider/pick-component-library'
import React from 'react'

function StopRealTimeInfo({ selectedNetwork, selectedStop }) {
  const { t } = useTranslation()

  function convertToLocaleTimeString(timestamp) {
    const date = new Date(timestamp * 1000)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }

  return (
    <Flex direction='column' gap='8px'>
      <Text fontSize='14px' fontWeight='600' color='#1D2939'>
        {t('network_schedule.real_time_information')}
      </Text>
      {selectedNetwork?.real_time?.length > 0 &&
        selectedNetwork?.real_time.some(bus => bus.stops.some(stop => stop.stopID === selectedStop)) && (
          <Flex gap='16px' justify='space-between' align='center'>
            <Flex direction='column' gap='8px'>
              <Tooltip
                hasArrow
                bg='#475467'
                rounded='4px'
                placement='bottom-start'
                label={t('network_schedule.scheduled_time_of_arrival')}
                aria-label={t('network_schedule.scheduled_time_of_arrival')}
              >
                <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                  STA
                </Text>
              </Tooltip>
              {selectedNetwork?.real_time.map(bus => {
                const busStop = bus.stops.find(stop => stop.stopID === selectedStop)
                if (busStop) {
                  return (
                    <Text fontSize='14px' color='#475467'>
                      {convertToLocaleTimeString(busStop?.scheduledTimestamp)}
                    </Text>
                  )
                }
                return null
              })}
            </Flex>
            <Flex direction='column' gap='8px'>
              <Tooltip
                hasArrow
                bg='#475467'
                rounded='4px'
                placement='bottom-start'
                label={t('network_schedule.estimated_time_of_arrival')}
                aria-label={t('network_schedule.estimated_time_of_arrival')}
              >
                <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                  ETA
                </Text>
              </Tooltip>
              {selectedNetwork?.real_time.map(bus => {
                const busStop = bus.stops.find(stop => stop.stopID === selectedStop)
                if (busStop) {
                  return (
                    <Flex gap='2px' align='center'>
                      <RealtimeIcon
                        size={'verySmall'}
                        fill={
                          busStop?.diff < -60 || busStop?.diff > 600
                            ? '#D23636'
                            : busStop?.diff > 180
                              ? '#C6901E'
                              : '#067F5B'
                        }
                      />
                      <Text
                        fontSize='14px'
                        color={
                          busStop?.diff < -60 || busStop?.diff > 600
                            ? '#D23636'
                            : busStop?.diff > 180
                              ? '#C6901E'
                              : '#067F5B'
                        }
                      >
                        {convertToLocaleTimeString(busStop?.realtimeTimestamp)}
                      </Text>
                    </Flex>
                  )
                }
                return null
              })}
            </Flex>
            <Flex direction='column' gap='8px' align='flex-end'>
              <Text fontSize='14px' fontWeight='700' color='#1D2939'>
                {t('network_schedule.status')}
              </Text>
              {selectedNetwork?.real_time.map(bus => {
                const busStop = bus.stops.find(stop => stop.stopID === selectedStop)
                if (busStop) {
                  return (
                    <Text
                      fontSize='13px'
                      fontWeight='600'
                      color={
                        busStop?.diff < -60 || busStop?.diff > 600
                          ? '#D23636'
                          : busStop?.diff > 180
                            ? '#C6901E'
                            : '#067F5B'
                      }
                    >
                      {busStop?.diff < -60
                        ? t('network_schedule.early')
                        : busStop?.diff > 180
                          ? t('network_schedule.delayed')
                          : busStop?.diff > 180
                            ? t('network_schedule.delayed')
                            : t('network_schedule.on_time')}
                    </Text>
                  )
                }
                return null
              })}
            </Flex>
          </Flex>
        )}
      {(selectedNetwork?.real_time?.length === 0 ||
        !selectedNetwork?.real_time?.some(bus => bus.stops.some(stop => stop.stopID === selectedStop))) && (
        <Flex padding='16px' bg='#E0EAFF' border='1px solid #C7D7FE' borderRadius='4px'>
          <Text fontSize='14px' color='#444CE7'>
            {t('network_schedule.issues_unable_to_display_realtime_map')}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export default StopRealTimeInfo
