import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import config from '../../../../config/configLoader'

export function FooterCopyright() {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  return (
    <Flex
      mt={{ sm: '48px', md: '48px', lg: '', xl: '' }}
      justify={{ sm: 'flex-start', md: 'flex-start', lg: 'center', xl: 'center' }}
      textAlign={{ lg: 'center', xl: 'center' }}
    >
      <Text fontSize='14px'>
        {config.info.copyright} {currentYear} {config.name}. {t('home.copyright')}
      </Text>
    </Flex>
  )
}
