import { Flex, Image, Text } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import config from '../../config/configLoader'
import { FooterLink } from './components/FooterLink'
import { FooterContacts } from './components/FooterContacts'

import footerLogo from '../../assets/footer_logo.svg'
import { FooterCopyright } from './components/FooterCopyright'
import { FooterFunding } from './components/FooterFunding'

export function Footer() {
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <>
      <Flex
        display={location.pathname.includes('/network-and-schedules') ? 'none' : 'flex'}
        as='footer'
        width='100%'
        height={{ sm: '67px', md: '67px', lg: '500px', xl: '500px' }}
        align={{ sm: 'flex-start', md: 'flex-start', lg: 'center', xl: 'center' }}
        justifyContent='flex-end'
        bg='#F9F9FB'
        pt={{ sm: '32px', md: '32px', lg: '70px', xl: '70px' }}
        pl={{ sm: '24px', md: '24px', lg: '70px', xl: '90px' }}
        pr={{ sm: '24px', md: '24px', lg: '70px', xl: '90px' }}
        pb={{ sm: '32px', md: '32px', lg: '70px', xl: '70px' }}
        direction='column'
        flex='1'
      >
        <Flex
          align='flex-start'
          direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
          gap={{ sm: '32px', md: '32px', lg: '48px', xl: '80px' }}
          flex='1'
          w='100%'
        >
          <Link to='/'>
            <Image src={footerLogo} width='127px' />
          </Link>

          <Flex direction='column' gap='18px' align='flex-start' flex='7'>
            <Text fontSize='24px' fontWeight='700' color='#212721'>
              {t('home.explore')}
            </Text>

            <Flex
              gap={{ sm: '32px', md: '32px', lg: '48px', xl: '80px' }}
              direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
            >
              <Flex direction='column' gap={{ sm: '8px', md: '8px', lg: '16px', xl: '16px' }} align='flex-start'>
                <FooterLink path={'/ticket-fares'} text={'ticket_fares.ticket_fares'} />
                <FooterLink path={'/network-and-schedules'} text={'network_schedule.network_schedule'} />
                {config.sections.news && <FooterLink path={'/news'} text={'news.news'} />}
                {config.sections.support && <FooterLink path={'/support'} text={'support.support'} />}
              </Flex>

              <Flex direction='column' gap={{ sm: '8px', md: '8px', lg: '16px', xl: '16px' }} align='flex-start'>
                {config.sections.about && <FooterLink path={'/about'} text={'about.about_operator'} />}
                <FooterLink path={'/ticket-fares#agents'} text={'ticket_fares.operator_agents'} />
                {config.sections.promotion && <FooterLink path={'/promotion'} text={'promotion.promotion'} />}
                {config.sections.recruitment && <FooterLink path={'/recruitment'} text={'recruitment.recruitment'} />}
              </Flex>

              <Flex
                direction='column'
                gap={{ sm: '8px', md: '8px', lg: '16px', xl: '16px' }}
                align='flex-start'
                justify='flex-start'
              >
                {config.sections.faqs && <FooterLink path={'/faqs'} text={'faqs.faqs_title'} newTab />}
                {config.sections.passenger_rights && (
                  <FooterLink path={'/passenger-rights'} text={'home.passenger_rights'} newTab />
                )}
                {config.sections.terms_conditions && (
                  <FooterLink path={'/terms-and-conditions'} text={'common.terms_conditions'} newTab />
                )}
                {config.sections.privacy_policy && (
                  <FooterLink path={'/privacy-policy'} text={'common.privacy_policy'} newTab />
                )}
                {config.sections.pass_request_policy && (
                  <FooterLink
                    path={'/pass-request-policy'}
                    text={'pass_request_policy.pass_request_policy_section_title'}
                    newTab
                  />
                )}
                {config.sections.cookie_policy && (
                  <FooterLink path={'/cookie-policy'} text={'cookie_policy.cookie_policy_description'} newTab />
                )}
              </Flex>
            </Flex>
          </Flex>

          <FooterContacts />
        </Flex>

        <FooterCopyright />
      </Flex>

      {config.sections.funding && !location.pathname.includes('/network-and-schedules') && <FooterFunding />}
    </>
  )
}
