import { Flex } from '@chakra-ui/react'
import { Text } from '@ubirider/pick-component-library'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import config from '../../../../config/configLoader'

export function HeaderLink(props) {
  const { t } = useTranslation()
  const location = useLocation()

  if (props?.mobile) {
    return (
      <Link className='chakra-custom-link' to={props.path} onClick={props.onClose} style={{ width: '100%' }}>
        <Flex
          w='100%'
          bg={location.pathname.includes(props.path) ? '#F2F4F7' : ''}
          rounded='4px'
          padding='12px 24px'
          h='48px'
          _hover={{ bg: 'F2F4F7' }}
        >
          <Text type='textMD' weight='semibold'>
            {t(props.text)}
          </Text>
        </Flex>
      </Link>
    )
  } else {
    return (
      <Flex borderBottom={location.pathname.includes(props.path) ? '2px solid ' + config.mainColor : ''}>
        <Link className='chakra-custom-link' to={props.path}>
          <Text type='textSM' weight={location.pathname.includes(props.path) ? 'bold' : 'semibold'}>
            {t(props.text)}
          </Text>
        </Link>
      </Flex>
    )
  }
}
