import { Flex, Image, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'

import stopSign from '../../../../assets/stop_sign.svg'

export function AffectedStopsComponent(props) {
  const { t } = useTranslation()

  return (
    <>
      <Text fontSize='14px' fontWeight='600' color='#1D2939'>
        {t('network_schedule.stops')}
      </Text>
      <Flex direction='column' gap='16px' align='flex-start' justify='end' width='100%'>
        <Wrap
          spacing={2}
          display={{ sm: 'none', xl: 'flex' }}
          align='center'
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow="'(More)'"
        >
          {props.alert?.affectedStops.map((stop, i) => (
            <WrapItem key={i} align='center'>
              <Flex
                padding='0 8px'
                borderTop='1px solid rgba(71, 84, 103, 0.40)'
                borderLeft='1px solid rgba(71, 84, 103, 0.40)'
                borderBottom='1px solid rgba(71, 84, 103, 0.40)'
                rounded='4px 0 0 4px'
                mr='4px'
                align='center'
              >
                <Image src={stopSign} h='22px' />
              </Flex>
              <Flex
                padding='1px 8px 1.5px 12px'
                ml='-4px'
                border='1px solid rgba(71, 84, 103, 0.40)'
                rounded='0 4px 4px 0'
                align='center'
              >
                <Text fontSize='13px' fontWeight='600' color='#1D2939'>
                  {props.network.stops
                    .find(networkStop => networkStop.id === stop.stopID)
                    .name.toLowerCase()
                    .replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
                </Text>
              </Flex>
            </WrapItem>
          ))}
        </Wrap>
        <Wrap spacing={2} display={{ sm: 'none', lg: 'flex', xl: 'none' }} align='center'>
          {props.alert?.affectedStops?.map((stop, i) => (
            <WrapItem key={i} align='center'>
              <Flex
                padding='0 8px'
                borderTop='1px solid rgba(71, 84, 103, 0.40)'
                borderLeft='1px solid rgba(71, 84, 103, 0.40)'
                borderBottom='1px solid rgba(71, 84, 103, 0.40)'
                rounded='4px 0 0 4px'
                mr='4px'
                align='center'
              >
                <Image src={stopSign} h='22px' />
              </Flex>
              <Flex
                padding='1px 8px 1.5px 12px'
                ml='-4px'
                border='1px solid rgba(71, 84, 103, 0.40)'
                rounded='0 4px 4px 0'
                align='center'
              >
                <Text fontSize='13px' fontWeight='600' color='#1D2939'>
                  {props.network.stops
                    .find(networkStop => networkStop.id === stop.stopID)
                    .name.toLowerCase()
                    .replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
                </Text>
              </Flex>
            </WrapItem>
          ))}
        </Wrap>
        <Wrap spacing={2} display={{ sm: 'flex', lg: 'none' }} align='center'>
          {props.alert?.affectedStops?.map((stop, i) => (
            <WrapItem key={i} align='center'>
              <Flex
                padding='0 8px'
                borderTop='1px solid rgba(71, 84, 103, 0.40)'
                borderLeft='1px solid rgba(71, 84, 103, 0.40)'
                borderBottom='1px solid rgba(71, 84, 103, 0.40)'
                rounded='4px 0 0 4px'
                mr='4px'
                align='center'
              >
                <Image src={stopSign} h='22px' />
              </Flex>
              <Flex
                padding='1px 8px 1.5px 12px'
                ml='-4px'
                border='1px solid rgba(71, 84, 103, 0.40)'
                rounded='0 4px 4px 0'
                align='center'
              >
                <Text fontSize='13px' fontWeight='600' color='#1D2939'>
                  {props.network.stops
                    .find(networkStop => networkStop.id === stop.stopID)
                    .name.toLowerCase()
                    .replace(/(?:^|\s)\S/g, char => char.toUpperCase())}
                </Text>
              </Flex>
            </WrapItem>
          ))}
        </Wrap>
      </Flex>
    </>
  )
}
